import {
  Actions,
  ButtonGroup,
  FilterAsync,
  FilterAsyncTokenBased,
  FiltersContainer,
  Loader,
  RangePicker,
  ScreenBar,
  Select,
  ViewContainer,
} from "components";
import { useNavigate, useParams } from "react-router";
import { useDownloadFile, useScreen, valueToQueryURL } from "utils";
import { WebAnalyticsScreenDefinition } from "./WebAnalytics.definition";
import {
  Box,
  Flex,
  Stack,
  Tag,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { FetchDataParamsWithToken, Nullable } from "types";
import {
  CampaignForList,
  CampaignPixel,
  CompanyPixel,
  Option,
  SortDirection,
  WebAnalyticsAccountView,
} from "@intentsify/types";
import {
  listAccounts,
  listCompanyPixels,
  listCompanyPixelCampaigns,
  listVisitedPages,
} from "./WebAnalytics.requests";
import { useUserAgencyCompany } from "../../../../queries/companies/useUserAgencyCompany";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { NoEntitySelected } from "../../../../shared/components";
import { useQuery } from "@tanstack/react-query";
import startCase from "lodash/startCase";
import camelCase from "lodash/camelCase";
import { isDefined } from "@intentsify/utils";
import { FiDownload, FiEdit3, FiMail, FiMoreVertical } from "react-icons/fi";
import { Endpoints } from "api";
import { CreatePixelScreenDefinition } from "../CreatePixel";
import {
  CumulativeSitePixelReportModal,
  DeliveryRecipientModal,
  ListView,
  Visualization,
} from "./components";
import { ampli } from "../../../../tracking/amplitude";

export enum WebAnalyticsView {
  Visualization = "visualization",
  ListView = "list-view",
}

export type ListPixelsParams = FetchDataParamsWithToken<keyof CampaignPixel>;

export const defaultListPixelsParams: ListPixelsParams = {
  pageToken: "",
  order_by: "displayName",
  order: SortDirection.ASC,
};

export type WebAnalyticsFiltersType = {
  accountView: Option<WebAnalyticsAccountView>;
  intentModel: Option<number> | null;
  startDate: string;
  endDate: string;
  accounts: Option[];
  visitedPages: Option[];
};

const listPixels =
  (companyId: number) =>
  async (
    params: FetchDataParamsWithToken<keyof CompanyPixel> & {
      pageSize?: number;
    }
  ) => {
    const { companyPixels, nextPageToken } = await listCompanyPixels(
      companyId,
      params
    );

    return {
      nextPageToken,
      results: companyPixels.map((pixel) => ({
        value: pixel.pixelId,
        label: pixel.displayName,
        meta: {
          domains: pixel.domains,
        },
      })),
    };
  };

export const listCampaigns =
  (companyId: number, pixelId: number) =>
  async (
    params: FetchDataParamsWithToken<keyof CampaignForList> & {
      pageSize?: number;
    }
  ) => {
    const { campaigns, nextPageToken } = await listCompanyPixelCampaigns(
      companyId,
      pixelId,
      params
    );

    return {
      nextPageToken,
      results: campaigns.map((campaign) => ({
        value: campaign.id,
        label: campaign.displayName,
      })),
    };
  };

const WebAnalytics = () => {
  useScreen(WebAnalyticsScreenDefinition);
  const navigate = useNavigate();
  const { download } = useDownloadFile();
  const { view } = useParams<"view">();
  const selectedView =
    (view as WebAnalyticsView | undefined) || WebAnalyticsView.Visualization;
  const userAgencyCompany = useUserAgencyCompany();
  const selectedCompanyId = userAgencyCompany.data?.companyId;

  const [selectedPixel, setSelectedPixel] =
    useState<Nullable<Option & { meta: { domains: string[] } }>>(null);

  const topbarColor = useColorModeValue("white", "brand.375");
  const colorGrey = useColorModeValue("gray.25", "brand.375");
  const {
    isOpen: isCumulativeSitePixelReportModalOpen,
    onOpen: onCumulativeSitePixelReportModalOpen,
    onClose: onCumulativeSitePixelReportModalClose,
  } = useDisclosure();
  const {
    isOpen: isDeliveryRecipientModalOpen,
    onOpen: onDeliveryRecipientModalOpen,
    onClose: onDeliveryRecipientModalClose,
  } = useDisclosure();

  useEffect(() => {
    setSelectedPixel(null);
    setFilters((prev) => ({
      ...prev,
      intentModel: null,
      accounts: [],
      accountView: {
        value: WebAnalyticsAccountView.AllAccounts,
        label: startCase(
          camelCase(WebAnalyticsAccountView.AllAccounts.split("_").join(" "))
        ),
      },
      visitedPages: [],
      startDate: DateTime.now().minus({ month: 1 }).toISO(),
      endDate: DateTime.now().toISO(),
    }));
  }, [selectedCompanyId]);

  const { data: companyPixels, isFetching: isLoadingCompanyPixels } = useQuery({
    queryKey: ["listCompanyPixels", selectedCompanyId],
    queryFn: async () => {
      return listCompanyPixels(
        Number(selectedCompanyId),
        defaultListPixelsParams
      );
    },
    enabled: !!selectedCompanyId,
  });

  const [filters, setFilters] = useState<WebAnalyticsFiltersType>({
    startDate: DateTime.now().minus({ month: 1 }).toISO(),
    endDate: DateTime.now().toISO(),
    accountView: {
      value: WebAnalyticsAccountView.AllAccounts,
      label: startCase(
        camelCase(WebAnalyticsAccountView.AllAccounts.split("_").join(" "))
      ),
    },
    intentModel: null,
    visitedPages: [],
    accounts: [],
  });

  const handleDownloadSelection = async () => {
    const params = {
      fileName: `${(selectedPixel?.label as string).replace(
        "-",
        "_"
      )}_${filters.startDate.slice(0, 10).replaceAll("-", "")}_${filters.endDate
        .slice(0, 10)
        .replaceAll("-", "")}`,
      url: Endpoints.WebAnalytics.Get.DownloadSelection(
        Number(selectedPixel?.value)
      ),
      fileExtension: "zip",
      params: {
        pixel: (selectedPixel as Option<string>).label,
        accountView: filters.accountView.value,
        ...(filters.intentModel?.value
          ? { campaignId: filters.intentModel?.value }
          : {}),
        accountsIds: filters.accounts.length
          ? valueToQueryURL(filters.accounts.map((item) => Number(item.value)))
          : undefined,
        visitedPages: filters.visitedPages.length
          ? valueToQueryURL(filters.visitedPages.map((item) => item.label))
          : undefined,
        startDate: filters.startDate.slice(0, 10),
        endDate: filters.endDate.slice(0, 10),
        order_by: "websiteVisits",
        order: SortDirection.DESC,
      },
    };

    await download(params);
  };

  return (
    <ViewContainer noPadding stretch>
      <Flex width="full" backgroundColor={topbarColor}>
        <Box maxWidth="1700px" width="full">
          <ScreenBar
            filters={[
              {
                label: "Pixel / Tag",
                width: "450",
                node: (
                  <Box data-testid="pixel-selector">
                    <FilterAsyncTokenBased
                      label={"Pixel / Tag"}
                      defaultOptions={[]}
                      onFilterValuesChange={(e) => {
                        if (e) {
                          setSelectedPixel(e);
                        }
                      }}
                      currentValue={selectedPixel}
                      dataRequest={listPixels(selectedCompanyId as number)}
                      isMulti={false}
                      showLabel={false}
                      isDisabled={!isDefined(selectedCompanyId)}
                      params={{
                        ...defaultListPixelsParams,
                      }}
                      resetFilterRequestDependencies={[selectedCompanyId]}
                    />
                  </Box>
                ),
              },
              {
                width: 200,
                node: (
                  <Actions
                    items={[
                      {
                        label: "Create New Pixel",
                        onClick: () => {
                          navigate(CreatePixelScreenDefinition.navigate());
                        },
                        icon: <FiEdit3 />,
                        variant: "primary-teal",
                      },
                      ...(selectedView === WebAnalyticsView.ListView &&
                      selectedPixel
                        ? [
                            {
                              label: "Export Analytics Report",
                              // eslint-disable-next-line @typescript-eslint/no-misused-promises
                              onClick: () => {
                                handleDownloadSelection();
                                ampli.websiteVisitorAnalyticsVisitingCompaniesDataExported(
                                  { pixelId: Number(selectedPixel.value) }
                                );
                              },
                              icon: <FiDownload />,
                            },
                            {
                              label: "Export Cumulative Site Report",
                              onClick: () =>
                                onCumulativeSitePixelReportModalOpen(),
                              icon: <FiDownload />,
                            },
                            {
                              label: "Schedule Automated Report",
                              onClick: () => onDeliveryRecipientModalOpen(),
                              icon: <FiMail />,
                            },
                          ]
                        : []),
                    ]}
                    numberOfVisibleItems={1}
                    iconsOnly={false}
                    buttonIcon={<FiMoreVertical />}
                    buttonsVariant="outline-teal"
                  />
                ),
              },
            ]}
            justifyContent="space-between"
          />
          <Box p={2}>
            {(selectedPixel?.meta.domains || []).map((domain, index) => (
              <Tag marginLeft={2} key={index}>
                {domain}
              </Tag>
            ))}
          </Box>
        </Box>
      </Flex>
      <Flex
        flexWrap="wrap"
        borderTop={2}
        borderTopStyle={"solid"}
        borderTopColor={colorGrey}
        maxWidth="1700px"
        width="full"
      >
        <Box
          px={4}
          py={2}
          backgroundColor={topbarColor}
          flexBasis="100%"
          minHeight="56px"
        >
          {selectedPixel && filters && (
            <FiltersContainer
              items={[
                {
                  component: (
                    <Stack>
                      <Text fontSize="xs">Account View</Text>
                      <Select
                        options={[
                          {
                            value: WebAnalyticsAccountView.IntentModel,
                            label: startCase(
                              camelCase(
                                WebAnalyticsAccountView.IntentModel.split(
                                  "_"
                                ).join(" ")
                              )
                            ),
                          },
                          {
                            value: WebAnalyticsAccountView.AllAccounts,
                            label: startCase(
                              camelCase(
                                WebAnalyticsAccountView.AllAccounts.split(
                                  "_"
                                ).join(" ")
                              )
                            ),
                          },
                        ]}
                        value={filters.accountView}
                        onChange={(e) => {
                          if (e) {
                            setFilters((prev) => {
                              return {
                                ...prev,
                                accountView: e,
                                intentModel:
                                  e.value ===
                                  WebAnalyticsAccountView.AllAccounts
                                    ? null
                                    : prev.intentModel,
                              };
                            });
                            ampli.websiteVisitorAnalyticsAccountViewFilterSelected(
                              {
                                pixelId: Number(selectedPixel.value),
                                accountViewFilterValue: e.value,
                              }
                            );
                          }
                        }}
                        isMulti={false}
                        isClearable={false}
                      />
                    </Stack>
                  ),
                },
                {
                  size: "xl",
                  component: (
                    <Stack>
                      <Text fontSize="xs">Intent Model</Text>
                      <FilterAsyncTokenBased
                        label={"Intent Model"}
                        defaultOptions={[]}
                        onFilterValuesChange={(e) => {
                          setFilters((prev) => {
                            return {
                              ...prev,
                              intentModel: e ?? null,
                            };
                          });
                          ampli.websiteVisitorAnalyticsIntentModelFilterSelected(
                            {
                              campaignId: e ? e.value : undefined,
                            }
                          );
                        }}
                        currentValue={filters.intentModel}
                        dataRequest={listCampaigns(
                          Number(selectedCompanyId),
                          Number(selectedPixel.value)
                        )}
                        isMulti={false}
                        showLabel={false}
                        isDisabled={
                          filters.accountView.value ===
                          WebAnalyticsAccountView.AllAccounts
                        }
                        params={{
                          ...defaultListPixelsParams,
                          pageSize: 50,
                        }}
                        resetFilterRequestDependencies={[selectedCompanyId]}
                      />
                    </Stack>
                  ),
                },
                {
                  component: (
                    <Stack>
                      <Text fontSize="xs">Date range:</Text>
                      <RangePicker
                        mode="day"
                        selected={{
                          start: filters.startDate,
                          end: filters.endDate,
                        }}
                        onChange={(val) => {
                          setFilters((prev) => {
                            return {
                              ...prev,
                              startDate: val.start,
                              endDate: val.end,
                            };
                          });
                          ampli.websiteVisitorAnalyticsDateRangeFilterSelected({
                            pixelId: Number(selectedPixel.value),
                            startDate: val.start.slice(0, 10),
                            endDate: val.end.slice(0, 10),
                          });
                        }}
                      />
                    </Stack>
                  ),
                },
                {
                  component: (
                    <Stack>
                      <Text fontSize="xs">Account</Text>
                      <FilterAsync
                        defaultOptions={[]}
                        currentValue={filters.accounts}
                        showLabel={false}
                        label="Account"
                        onFilterValuesChange={(value) => {
                          if (value && Array.isArray(value)) {
                            setFilters((prev) => {
                              return {
                                ...prev,
                                accounts: value,
                              };
                            });
                            ampli.websiteVisitorAnalyticsAccountsFilterSelected(
                              {
                                pixelId: Number(selectedPixel.value),
                                accountsIds: value.map((option) =>
                                  Number(option.value)
                                ),
                              }
                            );
                          }
                        }}
                        dataRequest={listAccounts(
                          Number(selectedPixel.value),
                          filters.accountView.value,
                          filters.startDate.slice(0, 10),
                          filters.endDate.slice(0, 10),
                          filters.intentModel?.value
                        )}
                        resetFilterRequestDependencies={[
                          selectedCompanyId,
                          selectedPixel.value,
                          filters.startDate.slice(0, 10),
                          filters.endDate.slice(0, 10),
                          filters.intentModel?.value || null,
                          filters.accountView.value,
                        ]}
                      />
                    </Stack>
                  ),
                },
                {
                  size: "xl",
                  component: (
                    <Stack>
                      <Text fontSize="xs">Page Visited</Text>
                      <FilterAsync
                        defaultOptions={[]}
                        currentValue={filters.visitedPages}
                        showLabel={false}
                        label="Visited Pages"
                        onFilterValuesChange={(value) => {
                          if (value && Array.isArray(value)) {
                            setFilters((prev) => {
                              return {
                                ...prev,
                                visitedPages: value,
                              };
                            });
                            ampli.websiteVisitorAnalyticsVisitedPageFilterSelected(
                              {
                                pixelId: Number(selectedPixel.value),
                                visitedPages: value.map((item) => item.label),
                              }
                            );
                          }
                        }}
                        dataRequest={listVisitedPages(
                          Number(selectedPixel.value),
                          filters.accountView.value,
                          filters.startDate.slice(0, 10),
                          filters.endDate.slice(0, 10),
                          filters.intentModel?.value || null,
                          filters.accounts.map((account) =>
                            Number(account.value)
                          )
                        )}
                        resetFilterRequestDependencies={[
                          selectedCompanyId,
                          selectedPixel.value,
                          filters.accountView.value,
                          filters.intentModel?.value || null,
                          filters.startDate.slice(0, 10),
                          filters.endDate.slice(0, 10),
                          filters.accounts.map((account) =>
                            Number(account.value)
                          ),
                        ]}
                      />
                    </Stack>
                  ),
                },
              ]}
              withMarginBottom={false}
            />
          )}
          {selectedPixel && filters && (
            <FiltersContainer
              items={[
                {
                  size: "xl",
                  component: (
                    <Stack w="800px">
                      <ButtonGroup
                        selectedValue={selectedView}
                        setSelectedValue={(view) => {
                          ampli.websiteVisitorAnalyticsViewSelected({
                            selectedView: view,
                          });
                          navigate(
                            WebAnalyticsScreenDefinition.navigate({
                              view,
                            })
                          );
                        }}
                        items={[
                          {
                            label: "Visitor Insights",
                            value: WebAnalyticsView.Visualization,
                          },
                          {
                            label: "Top Visiting Companies",
                            value: WebAnalyticsView.ListView,
                          },
                        ]}
                      />
                    </Stack>
                  ),
                },
              ]}
              withMarginBottom={false}
            />
          )}
        </Box>
        <Box width={"full"} maxWidth="1700px">
          {isLoadingCompanyPixels ? (
            <Loader label={"Loading company pixels..."} />
          ) : (
            <>
              {!selectedPixel?.label ? (
                <NoEntitySelected
                  entityName="pixel"
                  hasData={(companyPixels?.companyPixels || []).length > 0}
                  noDataMessage={
                    "For website visitor analytics, create and deploy the Intentsify tracking pixel on your website by clicking on “Create Pixel” above."
                  }
                />
              ) : (
                <ViewContainer>
                  {selectedView === WebAnalyticsView.Visualization && (
                    <Visualization
                      pixelId={Number(selectedPixel.value)}
                      accountView={filters.accountView.value}
                      campaignId={filters.intentModel?.value || null}
                      startDate={filters.startDate.slice(0, 10)}
                      endDate={filters.endDate.slice(0, 10)}
                      accountsIds={filters.accounts.map((item) =>
                        Number(item.value)
                      )}
                      visitedPages={filters.visitedPages.map(
                        (item) => item.label
                      )}
                    />
                  )}
                  {selectedView === WebAnalyticsView.ListView && (
                    <ListView
                      pixelId={Number(selectedPixel.value)}
                      accountView={filters.accountView.value}
                      campaignId={filters.intentModel?.value || null}
                      startDate={filters.startDate.slice(0, 10)}
                      endDate={filters.endDate.slice(0, 10)}
                      accountsIds={filters.accounts.map((item) =>
                        Number(item.value)
                      )}
                      visitedPages={filters.visitedPages.map(
                        (item) => item.label
                      )}
                    />
                  )}
                </ViewContainer>
              )}
            </>
          )}
        </Box>
      </Flex>
      {selectedCompanyId && selectedPixel?.value && (
        <>
          <CumulativeSitePixelReportModal
            companyId={Number(selectedCompanyId)}
            pixelId={Number(selectedPixel.value)}
            isOpen={isCumulativeSitePixelReportModalOpen}
            onClose={onCumulativeSitePixelReportModalClose}
            startDate={filters.startDate.slice(0, 10)}
            endDate={filters.endDate.slice(0, 10)}
          />
          <DeliveryRecipientModal
            companyId={Number(selectedCompanyId)}
            pixelId={Number(selectedPixel.value)}
            isOpen={isDeliveryRecipientModalOpen}
            onClose={onDeliveryRecipientModalClose}
          />
        </>
      )}
    </ViewContainer>
  );
};

export { WebAnalytics };
