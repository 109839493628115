import { BarChart, Card, MIN_MODAL_CONTENT_HEIGHT } from "components";
import { useMemo, useState } from "react";
import { ChartExport, useExportableChart } from "shared/components";
import { useBuyingGroupInsight } from "../useBuyingGroupInsight";

interface BuyingGroupContactByJobLevelProps {
  campaignId: number;
}

export function BuyingGroupContactByJobLevel(
  props: BuyingGroupContactByJobLevelProps
) {
  const [isExpanded, setIsExpanded] = useState(false);
  const buyingGroupInsight = useBuyingGroupInsight(props.campaignId);
  const data = useMemo(
    () =>
      (buyingGroupInsight.data?.jl_dist ?? [])
        .filter((item) => item.cont_per_jl && item.jl)
        .map((item) => ({
          // TODO: as unknown will be fixed by ts 5.5
          value: item.cont_per_jl as unknown as number,
          label: item.jl as unknown as string,
        })),
    [buyingGroupInsight.data?.jl_dist]
  );

  const chart = useExportableChart({
    title: "Buying Group Contact By Job Level",
    campaignId: props.campaignId,
    chart: (
      <BarChart
        stretch
        data={data}
        orientation="vertical"
        valueAxisLabel="Contacts"
        maxXAxisHeight={70}
        minHeight={isExpanded ? MIN_MODAL_CONTENT_HEIGHT : undefined}
      />
    ),
  });

  return (
    <Card
      isLoading={buyingGroupInsight.isFetching}
      onExpandedChange={setIsExpanded}
      title={chart.title}
      hasData={!buyingGroupInsight.isFetching && data?.length > 0}
      isExpandable
      actions={
        <ChartExport
          size="small"
          campaignId={1}
          title={chart.title}
          onExportPNG={chart.downloadAsPng}
          data={data ?? []}
        />
      }
    >
      {chart.component}
      {chart.exportComponent}
    </Card>
  );
}
