import { chakra, useToast } from "@chakra-ui/react";
import { Button, Loader, Modal } from "components";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { DeliveryRecipientFormState, deliveryRecipientSchema } from "screens";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createCompanyPixelDeliveryRecipient } from "../../../EditPixel/EditPixel.requests";
import {
  MutateCompanyPixelDeliveryRecipientDTO,
  CompanyPixelDeliveryRecipientRO,
} from "@intentsify/dto";
import { DeliveryRecipientForm } from "../../../components";
import React, { useEffect } from "react";
import { useUser } from "../../../../../../store/store.hooks";
import { WebAnalyticsAccountView } from "@intentsify/types";
import { DateTime } from "luxon";
import { ampli } from "../../../../../../tracking/amplitude";

export const DeliveryRecipientModal = ({
  companyId,
  pixelId,
  isOpen,
  onClose,
}: {
  companyId: number;
  pixelId: number;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const queryClient = useQueryClient();
  const toast = useToast();

  const {
    register,
    formState: { errors },
    setValue,
    getValues,
    watch,
    control,
    reset,
  } = useForm<DeliveryRecipientFormState>({
    mode: "onChange",
    resolver: zodResolver(deliveryRecipientSchema),
    defaultValues: {
      accountView: WebAnalyticsAccountView.AllAccounts,
      campaignId: null,
      startDate: DateTime.now().toISO(),
      endDate: DateTime.now().plus({ month: 1 }).toISO(),
    },
  });

  const user = useUser();

  const { mutate: createRecipient, isLoading } = useMutation({
    mutationFn: async (variables: {
      companyId: number;
      pixelId: number;
      params: MutateCompanyPixelDeliveryRecipientDTO;
    }) => {
      const { companyId, pixelId, params } = variables;
      return createCompanyPixelDeliveryRecipient(companyId, pixelId, params);
    },
    onSuccess: (data: CompanyPixelDeliveryRecipientRO) => {
      toast({
        title: `Report for ${user?.email || ""} scheduled.`,
        status: "success",
      });
      queryClient.invalidateQueries({
        queryKey: ["getCompanyPixelDeliveryRecipients", pixelId],
      });
      ampli.websiteVisitorAnalyticsReportDeliveryRecipientCreated({
        pixelId,
        recipientId: data.companyPixelDeliveryRecipient.deliveryRecipientId,
      });
      onClose();
    },
  });

  useEffect(() => {
    setValue("email", user?.email || "");
  }, [setValue, user]);

  const onSubmit = () => {
    const data = getValues();
    createRecipient({
      companyId,
      pixelId,
      params: {
        email: data.email,
        startDate: data.startDate.slice(0, 10),
        endDate: data.endDate.slice(0, 10),
        filters: {
          accountView: data.accountView,
          campaignId: data.campaignId,
        },
      },
    });
  };

  const { email, startDate, endDate, accountView, campaignId } = watch();

  return (
    <Modal
      overflow="visible"
      title={"Schedule Automated Report"}
      isOpen={isOpen}
      onClose={() => {
        reset({ email: user?.email || "" });
        onClose();
      }}
      size="2xl"
      body={
        !isLoading ? (
          <chakra.form width="100%">
            <DeliveryRecipientForm
              companyId={companyId}
              pixelId={pixelId}
              register={register}
              errors={errors}
              recipient={{
                email,
                startDate,
                endDate,
                accountView,
                campaignId,
                campaignName: null,
              }}
              control={control}
              setValue={setValue}
            />
          </chakra.form>
        ) : (
          <Loader label="" minHeight={400} />
        )
      }
      primaryButton={
        !isLoading && (
          <Button
            size="md"
            variant="primary-teal"
            onClick={() => {
              onSubmit();
            }}
          >
            Create
          </Button>
        )
      }
    />
  );
};
