import {
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  SimpleGrid,
  useDisclosure,
  Text,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { GetFiltersRO } from "@intentsify/dto";
import {
  AudienceSegmentDefinition,
  AudienceSegmentOption,
  audienceSegmentDefinition,
  brsOptions,
  topicStageOptions,
  TopicStage,
} from "@intentsify/types";
import {
  Button,
  LabeledSwitch,
  RangeFilter,
  Select,
  TreeFilter,
} from "components";
import { useMemo } from "react";
import { Controller, DefaultValues, useForm } from "react-hook-form";
import { FiEdit, FiPlus } from "react-icons/fi";
import { useSetRecoilState } from "recoil";
import { useDeepEffect } from "utils";
import { useBusinessEventsTree } from "utils/useBusinessEventsTree";
import { useUpdateAudienceSegment } from "../../AudienceSegments.requests";
import { AudienceSegmentsState } from "../../AudienceSegments.state";
import { AudienceSegmentsModal } from "../AudienceSegmentsModal/AudienceSegmentsModal";

type AudienceSegmentFormProps = {
  companyId: number;
  selectedAudienceSegmentId: number | null;
  onCreate: (audienceSegment: AudienceSegmentOption) => void;
  filtersOptions: GetFiltersRO;
  initialValues: DefaultValues<AudienceSegmentDefinition>;
  onApplyAudienceSegment: (value: AudienceSegmentDefinition) => void;
};

const AudienceSegmentsForm = ({
  companyId,
  onCreate,
  selectedAudienceSegmentId,
  filtersOptions,
  initialValues,
  onApplyAudienceSegment,
}: AudienceSegmentFormProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const update = useUpdateAudienceSegment();

  const form = useForm<AudienceSegmentDefinition>({
    resolver: zodResolver(audienceSegmentDefinition),
    defaultValues: initialValues,
  });

  const businessEventsTree = useBusinessEventsTree(
    filtersOptions?.businessEvents
  );

  const businessEvents = form.watch("businessEvents");

  const includedBusinessEvents = useMemo(() => {
    return filtersOptions?.businessEvents.filter((o) =>
      businessEvents.includes(o.value)
    );
  }, [businessEvents, filtersOptions?.businessEvents]);

  const errors = form.formState.errors as typeof form.formState.errors & {
    general?: { message: string };
  };

  const setAudienceDefinition = useSetRecoilState(
    AudienceSegmentsState.audienceSegmentDefinitionAtom
  );

  useDeepEffect(() => {
    form.reset(initialValues);
  }, [initialValues]);

  return (
    <Flex as="form" direction={"column"} width="100%">
      <SimpleGrid spacing={"4"} columns={4} width="100%">
        <FormControl>
          <FormLabel fontSize="xs" fontWeight="semibold">
            Buyer Research Stage
          </FormLabel>
          <Controller
            name="buyerResearchStage"
            control={form.control}
            render={({ field }) => (
              <Select
                isMulti
                placeholder="Select BRS stages"
                options={brsOptions}
                value={brsOptions.filter((option) =>
                  field.value?.includes(option.value)
                )}
                onChange={(value) => {
                  if (value && Array.isArray(value)) {
                    field.onChange(value.map((option) => option.value));
                  }
                }}
              />
            )}
          />

          <FormErrorMessage>
            {errors.buyerResearchStage?.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl>
          <FormLabel fontSize="xs" fontWeight="semibold">
            Topics
          </FormLabel>
          <Controller
            name="topics"
            control={form.control}
            render={({ field: { onChange, value } }) => (
              <Select
                isMulti
                placeholder="Topics"
                value={filtersOptions.topics.filter((option) =>
                  value?.includes(option.label)
                )}
                options={filtersOptions.topics}
                isLoading={!filtersOptions.topics}
                onChange={(value) => {
                  if (value && Array.isArray(value)) {
                    onChange(value.map((option) => option.value));
                  }
                }}
              />
            )}
          />

          <FormErrorMessage>{errors.topics?.message}</FormErrorMessage>
        </FormControl>

        <FormControl>
          <FormLabel fontSize="xs" fontWeight="semibold">
            Keywords
          </FormLabel>
          <Controller
            name="keywords"
            control={form.control}
            render={({ field: { onChange, value } }) => (
              <Select
                isMulti
                placeholder="Keywords"
                value={filtersOptions.keywords.filter((option) => {
                  return value?.includes(option.label);
                })}
                options={filtersOptions.keywords}
                isLoading={!filtersOptions.keywords}
                onChange={(value) => {
                  if (value && Array.isArray(value)) {
                    onChange(value.map((option) => option.value));
                  }
                }}
              />
            )}
          />
          <FormErrorMessage>{errors.keywords?.message}</FormErrorMessage>
        </FormControl>

        <FormControl>
          <FormLabel fontSize="xs" fontWeight="semibold">
            Topic Stage
          </FormLabel>
          <Controller
            name="topicStage"
            control={form.control}
            render={({ field: { onChange, value } }) => (
              <Select
                isMulti={false}
                isClearable={true}
                placeholder="Topic Stage"
                options={topicStageOptions}
                value={topicStageOptions.find(
                  (option) => option.value === value
                )}
                onChange={(value) => {
                  onChange(value?.value || TopicStage.Unspecified);
                }}
              />
            )}
          />
          <FormErrorMessage>{errors.topicStage?.message}</FormErrorMessage>
        </FormControl>

        <FormControl>
          <FormLabel fontSize="xs" fontWeight="semibold">
            Accounts
          </FormLabel>
          <Controller
            key={selectedAudienceSegmentId}
            name="domains"
            control={form.control}
            render={({ field: { onChange, value } }) => (
              <Select
                isMulti
                placeholder="Accounts"
                value={filtersOptions.domains.filter((option) => {
                  return value?.includes(option.label);
                })}
                options={filtersOptions.domains}
                onChange={(value) => {
                  if (value && Array.isArray(value)) {
                    onChange(value.map((option) => option.value));
                  }
                }}
              />
            )}
          />
          <FormErrorMessage>{errors.domains?.message}</FormErrorMessage>
        </FormControl>

        <FormControl>
          <FormLabel fontSize="xs" fontWeight="semibold">
            Locations
          </FormLabel>
          <Controller
            name="locations"
            control={form.control}
            render={({ field: { onChange, value } }) => (
              <Select
                isMulti
                placeholder="Locations"
                options={filtersOptions.locations}
                value={filtersOptions.locations.filter((option) => {
                  return value?.includes(option.label);
                })}
                onChange={(value) => {
                  if (value && Array.isArray(value)) {
                    onChange(value.map((option) => option.value));
                  }
                }}
              />
            )}
          />
          <FormErrorMessage>{errors.locations?.message}</FormErrorMessage>
        </FormControl>

        <FormControl>
          <FormLabel fontSize="xs" fontWeight="semibold">
            Custom Sources
          </FormLabel>
          <Controller
            name="customSources"
            control={form.control}
            render={({ field: { onChange, value } }) => (
              <Select
                isMulti
                placeholder="Custom Sources"
                options={filtersOptions.customSources}
                value={filtersOptions.customSources.find((option) => {
                  return value?.includes(option.label);
                })}
                onChange={(value) => {
                  if (value && Array.isArray(value)) {
                    onChange(value.map((option) => option.value));
                  }
                }}
              />
            )}
          />
          <FormErrorMessage>{errors.customSources?.message}</FormErrorMessage>
        </FormControl>

        <FormControl>
          <FormLabel fontSize="xs" fontWeight="semibold">
            Business Events
          </FormLabel>
          <Controller
            name="businessEvents"
            control={form.control}
            render={({ field: { onChange } }) => (
              <TreeFilter
                expandOnInitialRender
                subject="Business Events"
                nodes={businessEventsTree}
                included={includedBusinessEvents}
                onIncludedChange={(val) => {
                  onChange(val.map((i) => i.value));
                }}
              />
            )}
          />
          <FormErrorMessage>{errors.customSources?.message}</FormErrorMessage>
        </FormControl>
      </SimpleGrid>
      <SimpleGrid mt={4} spacing={"8"} columns={2} width="100%">
        <FormControl>
          <FormLabel fontSize="xs" fontWeight="semibold">
            Active Sources
          </FormLabel>
          <Controller
            name="activeSources"
            control={form.control}
            render={({ field: { onChange } }) => (
              <RangeFilter
                step={1}
                min={0}
                max={10}
                onChange={onChange}
                value={initialValues.activeSources as [number, number]}
              />
            )}
          />
          <FormErrorMessage>{errors.activeSources?.message}</FormErrorMessage>
        </FormControl>

        <FormControl>
          <FormLabel fontSize="xs" fontWeight="semibold">
            Signal Strength
          </FormLabel>
          <Controller
            name="signalStrength"
            control={form.control}
            render={({ field: { onChange } }) => (
              <RangeFilter
                step={1}
                min={0}
                max={10}
                onChange={onChange}
                value={initialValues.signalStrength as [number, number]}
              />
            )}
          />
          <FormErrorMessage>{errors.signalStrength?.message}</FormErrorMessage>
        </FormControl>
      </SimpleGrid>
      <SimpleGrid mt={4} spacing={"4"} columns={5} width="100%">
        <FormControl
          isInvalid={!!errors?.minWebsiteActivity?.message}
          isRequired
        >
          <Text fontSize="xx-small">&#x200b;</Text>
          <FormLabel fontSize="xs" fontWeight="semibold">
            Min. Website Visits
          </FormLabel>
          <Controller
            name="minWebsiteActivity"
            control={form.control}
            render={({ field: { value, onChange } }) => (
              <NumberInput
                shadow="sm"
                size="sm"
                rounded="md"
                maxWidth="200px"
                defaultValue={0}
                max={Number.MAX_SAFE_INTEGER}
                min={0}
                step={1}
                value={value}
                onChange={(e) => {
                  onChange(Number(e));
                }}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            )}
          ></Controller>

          <FormErrorMessage>
            {errors?.minWebsiteActivity?.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors?.minLeads?.message} isRequired>
          <Text fontSize="xx-small">(Content Syndication)</Text>
          <FormLabel fontSize="xs" fontWeight="semibold">
            Min. Leads Delivered
          </FormLabel>
          <Controller
            name="minLeads"
            control={form.control}
            render={({ field: { value, onChange } }) => (
              <NumberInput
                shadow="sm"
                size="sm"
                rounded="md"
                maxWidth="200px"
                defaultValue={0}
                max={Number.MAX_SAFE_INTEGER}
                min={0}
                step={1}
                value={value}
                onChange={(e) => {
                  onChange(Number(e));
                }}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            )}
          ></Controller>

          <FormErrorMessage>{errors?.minLeads?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors?.minImpressions?.message} isRequired>
          <Text fontSize="xx-small">(Ad Solutions)</Text>
          <FormLabel fontSize="xs" fontWeight="semibold">
            Min. Impressions Served
          </FormLabel>
          <Controller
            name="minImpressions"
            control={form.control}
            render={({ field: { value, onChange } }) => (
              <NumberInput
                shadow="sm"
                size="sm"
                rounded="md"
                maxWidth="200px"
                defaultValue={0}
                max={Number.MAX_SAFE_INTEGER}
                min={0}
                step={1}
                value={value}
                onChange={(e) => {
                  onChange(Number(e));
                }}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            )}
          ></Controller>

          <FormErrorMessage>{errors?.minImpressions?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors?.minClicks?.message} isRequired>
          <Text fontSize="xx-small">(Ad Solutions)</Text>
          <FormLabel fontSize="xs" fontWeight="semibold">
            Min. Clicks
          </FormLabel>
          <Controller
            name="minClicks"
            control={form.control}
            render={({ field: { value, onChange } }) => (
              <NumberInput
                shadow="sm"
                size="sm"
                rounded="md"
                maxWidth="200px"
                defaultValue={0}
                max={Number.MAX_SAFE_INTEGER}
                min={0}
                step={1}
                value={value}
                onChange={(e) => {
                  onChange(Number(e));
                }}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            )}
          ></Controller>

          <FormErrorMessage>{errors?.minClicks?.message}</FormErrorMessage>
        </FormControl>

        <FormControl>
          <FormLabel fontSize="xs" fontWeight="semibold">
            Spotlight Ready Accounts
          </FormLabel>
          <Controller
            name="hasSpotlight"
            control={form.control}
            render={({ field: { onChange, value } }) => (
              <LabeledSwitch
                isChecked={value}
                size="sm"
                leftLabel="Off"
                rightLabel="On"
                onChange={onChange}
              />
            )}
          />
          <FormErrorMessage>{errors.hasSpotlight?.message}</FormErrorMessage>
        </FormControl>
      </SimpleGrid>

      <Flex alignItems="center" gap="4" alignSelf="end" mt="8">
        <ButtonGroup>
          <Button
            variant="secondary"
            onClick={() => {
              onApplyAudienceSegment(form.getValues());
              setAudienceDefinition(form.getValues());
            }}
          >
            Apply Filters
          </Button>
          {selectedAudienceSegmentId ? (
            <Button
              variant="primary-teal"
              onClick={() => {
                update.mutate({
                  companyId,
                  id: selectedAudienceSegmentId,
                  definition: form.getValues(),
                });
              }}
              leftIcon={<FiEdit />}
            >
              Update Segment
            </Button>
          ) : (
            <Button
              variant="primary-teal"
              onClick={onOpen}
              leftIcon={<FiPlus />}
            >
              Create Segment
            </Button>
          )}
        </ButtonGroup>
      </Flex>

      <AudienceSegmentsModal
        companyId={companyId}
        isOpen={isOpen}
        onCreate={onCreate}
        onClose={onClose}
        definition={form.getValues()}
      />
    </Flex>
  );
};

export { AudienceSegmentsForm };
