import {
  BaseTargetPersona,
  BuyingGroupContactsAccounts,
  CampaignBuyingGroup,
} from "@intentsify/types";
import isEqual from "lodash/isEqual";
import { useMemo } from "react";

export const haveCampaignsPersonasChanges = (
  oldBuyingGroup: CampaignBuyingGroup,
  selectedPersonas: BaseTargetPersona[],
  maxBuyerGroupSizePerAccount: number,
  buyingGroupContactsAccounts: BuyingGroupContactsAccounts,
  buyingGroupEqualDistribution: boolean
) => {
  const oldPersonasIds = oldBuyingGroup.selectedPersonas
    .map(({ id }) => id)
    .sort();
  const personaIds = selectedPersonas.map(({ id }) => id).sort();

  return (
    !isEqual(oldPersonasIds, personaIds) ||
    oldBuyingGroup.maxBuyerGroupSizePerAccount !==
      maxBuyerGroupSizePerAccount ||
    oldBuyingGroup.buyingGroupContactsAccounts !==
      buyingGroupContactsAccounts ||
    oldBuyingGroup.buyingGroupEqualDistribution !== buyingGroupEqualDistribution
  );
};

export const useWatchCampaignPersonas = (
  campaignBuyingGroup: CampaignBuyingGroup,
  selectedPersonas: BaseTargetPersona[],
  maxBuyerGroupSizePerAccount: number,
  buyingGroupContactsAccounts: BuyingGroupContactsAccounts,
  buyingGroupEqualDistribution: boolean
) => {
  return useMemo(() => {
    return haveCampaignsPersonasChanges(
      campaignBuyingGroup,
      selectedPersonas,
      maxBuyerGroupSizePerAccount,
      buyingGroupContactsAccounts,
      buyingGroupEqualDistribution
    );
  }, [
    campaignBuyingGroup,
    selectedPersonas,
    maxBuyerGroupSizePerAccount,
    buyingGroupContactsAccounts,
    buyingGroupEqualDistribution,
  ]);
};
