import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  ColumnDef,
  getCoreRowModel,
  OnChangeFn,
  Row as RowType,
  RowSelectionState,
  SortingState,
  Table,
  useReactTable,
} from "@tanstack/react-table";
import { useToggle } from "react-use";
import { useVirtualizer } from "@tanstack/react-virtual";
import {
  Flex,
  Table as ChakraTable,
  Tbody,
  Td,
  Thead,
  Tr,
  Box,
} from "@chakra-ui/react";
import { InfiniteData, useInfiniteQuery } from "@tanstack/react-query";
import {
  FetchDataParams,
  PaginatedResponse,
  SortDirection,
  TokenPaginatedResponse,
} from "@intentsify/types";
import { isDefined } from "@intentsify/utils";
import { HeaderGroup, Row } from "../components";
import { PaginationType } from "../Table/Table.types";
import { Loader } from "../../Loader";
import { NoData } from "../../NoData";
import { ExpanderIcon } from "components/ExpanderIcon";
import { IndeterminateCheckbox } from "components/IndeterminateCheckbox/IndeterminateCheckbox";
import { FetchDataParamsWithToken } from "types";

type FetchDataWithLimitOffset<T extends Record<string, unknown>> = (
  params: FetchDataParams<keyof T>
) => Promise<PaginatedResponse<T>>;

type FetchDataWithToken<T extends Record<string, unknown>> = (
  params: FetchDataParamsWithToken<keyof T>
) => Promise<TokenPaginatedResponse<T>>;

type FetchDataFunctionType<
  T extends Record<string, unknown>,
  K extends PaginationType.LIMIT_OFFSET | PaginationType.TOKEN
> = K extends PaginationType.TOKEN
  ? FetchDataWithToken<T>
  : FetchDataWithLimitOffset<T>;

type ResponseType<
  T extends Record<string, unknown>,
  PT extends PaginationType.LIMIT_OFFSET | PaginationType.TOKEN
> = PT extends PaginationType.TOKEN
  ? TokenPaginatedResponse<T>
  : PaginatedResponse<T>;

type Props<
  T extends Record<string, unknown>,
  PT extends PaginationType.LIMIT_OFFSET | PaginationType.TOKEN
> = {
  bg?: string;
  columns: ColumnDef<T>[];
  rowSelection?: RowSelectionState;
  defaultSort?: SortingState;
  fetchData: FetchDataFunctionType<T, PT>;
  height: number;
  maxWidth?: string;
  name: string;
  setRowSelection?: OnChangeFn<RowSelectionState>;
  renderExpandableRowComponent?: (row: RowType<T>) => ReactNode;
  isSelectable?: boolean;
  search?: string;
  width?: string;
  variant?: "intentsifyPlain";
  paginationType?: PaginationType;
  estimatedRowHeight: number;
  disableVirtualization?: boolean;
  filters?: Record<string, any>;
  itemsPerPage?: number;
  onLoadingStateChange?: (
    isLoading: boolean,
    data: InfiniteData<ResponseType<T, PT>>
  ) => void;
  noDataMessage?: string;
  onRowExpandToggle?: (isExpanded: boolean, row: T) => void;
};

const InfiniteScrollTable = <
  T extends Record<string, unknown>,
  PT extends PaginationType.TOKEN | PaginationType.LIMIT_OFFSET
>({
  bg,
  name,
  columns,
  fetchData,
  search = "",
  defaultSort,
  isSelectable = false,
  setRowSelection,
  rowSelection = {},
  maxWidth = "100%",
  height,
  variant,
  renderExpandableRowComponent,
  width,
  paginationType = PaginationType.TOKEN,
  estimatedRowHeight,
  disableVirtualization = false,
  filters = {},
  itemsPerPage,
  onLoadingStateChange,
  noDataMessage,
  onRowExpandToggle,
}: Props<T, PT>) => {
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const [allSelected, toggleAllSelected] = useToggle(false);

  const tableColumns = useMemo(() => {
    return [
      ...(isSelectable
        ? [
            {
              id: "select",
              header: ({ table }: { table: Table<T> }) => (
                <IndeterminateCheckbox
                  {...{
                    checked: allSelected,
                    indeterminate: table.getIsSomeRowsSelected(),
                    onChange: (e) => {
                      toggleAllSelected();
                      const toggleAllRowsSelectionHandler =
                        table.getToggleAllRowsSelectedHandler();
                      toggleAllRowsSelectionHandler(e);
                    },
                  }}
                />
              ),
              cell: ({ row }: { row: RowType<T> }) => {
                return (
                  <IndeterminateCheckbox
                    {...{
                      checked: row.getIsSelected(),
                      indeterminate: row.getIsSomeSelected(),
                      onChange: row.getToggleSelectedHandler(),
                    }}
                  />
                );
              },
              size: 48,
              enableResizing: false,
            },
          ]
        : []),
      ...columns,
      ...(typeof renderExpandableRowComponent !== "undefined"
        ? [
            {
              accessorKey: "expandable-column",
              header: "",
              cell: ({ row }: { row: RowType<T> }) => {
                return (
                  <ExpanderIcon
                    isOpen={row.getIsExpanded()}
                    iconProps={{ w: 8, h: 8 }}
                    onClick={() => {
                      onRowExpandToggle?.(row.getIsExpanded(), row.original);
                      row.toggleExpanded();
                    }}
                  />
                );
              },
              enableSorting: false,
            },
          ]
        : []),
    ];
  }, [
    allSelected,
    columns,
    isSelectable,
    onRowExpandToggle,
    renderExpandableRowComponent,
    toggleAllSelected,
  ]);

  const [sorting, setSorting] = useState<SortingState>(
    defaultSort ? defaultSort : []
  );

  const tableVariant = variant
    ? variant
    : typeof renderExpandableRowComponent !== "undefined"
    ? "intentsifyExpanded"
    : "intentsifyStriped";

  const { data, fetchNextPage, isFetchingNextPage, isFetching, isLoading } =
    useInfiniteQuery<ResponseType<T, PT>>(
      [name, sorting, search, filters],
      // @ts-ignore
      (params) => {
        const sort = sorting[0];
        const order =
          sort?.desc === true ? SortDirection.DESC : SortDirection.ASC;
        const orderBy = sort?.id;

        if (paginationType === PaginationType.TOKEN) {
          return (fetchData as FetchDataWithToken<T>)({
            order,
            order_by: orderBy,
            pageToken: params.pageParam,
            ...(search
              ? {
                  search,
                }
              : {}),
          });
        } else {
          return (fetchData as FetchDataWithLimitOffset<T>)({
            order,
            order_by: orderBy,
            page: params.pageParam ? Number(params.pageParam) + 1 : 1,
            per_page: itemsPerPage || 0,
            ...(search
              ? {
                  search,
                }
              : {}),
          });
        }
      },
      {
        getNextPageParam: (lastPage, groups) => {
          if (paginationType === PaginationType.TOKEN) {
            const page = lastPage as TokenPaginatedResponse<T>;

            if (!page?.nextPageToken) {
              return;
            }

            return page.nextPageToken;
          } else {
            const page = lastPage as PaginatedResponse<T>;

            if (page?.meta.current_page >= page?.meta.total_pages) {
              return;
            }

            return groups.length;
          }
        },
        keepPreviousData: false,
        refetchOnWindowFocus: false,
      }
    );

  // we must flatten the array of arrays from the useInfiniteQuery hook
  const flatData = useMemo(
    () =>
      (data?.pages as unknown as ResponseType<T, PT>[])
        ?.flatMap((page) => page?.results)
        ?.filter(isDefined) ?? [],
    [data]
  );

  const table = useReactTable({
    data: flatData,
    columns: tableColumns,
    state: {
      rowSelection: rowSelection,
      sorting,
    },
    defaultColumn: {
      minSize: 0,
      size: 0,
    },
    enableRowSelection: isSelectable,
    onRowSelectionChange: setRowSelection,
    onSortingChange: (e) => {
      setSorting(e);
      setRowSelection?.({});
    },
    getCoreRowModel: getCoreRowModel(),
  });

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        // once the user has scrolled within half of the table's height of the bottom of the table, fetch more data if there is any
        if (
          scrollHeight - scrollTop - clientHeight < 0.5 * height &&
          !isFetchingNextPage
        ) {
          fetchNextPage();
          // if "Select all" was clicked mark all records as checked (including recently fetched ones)
          if (isSelectable && allSelected) {
            table.toggleAllRowsSelected(allSelected);
          }
        }
      }
    },
    [
      height,
      isFetchingNextPage,
      fetchNextPage,
      isSelectable,
      allSelected,
      table,
    ]
  );

  useEffect(() => {
    const isLoadingOrFetching = isLoading || isFetching;
    onLoadingStateChange?.(
      isLoadingOrFetching,
      data as InfiniteData<ResponseType<T, PT>>
    );
  }, [data, isFetching, isLoading, onLoadingStateChange, paginationType]);

  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [fetchMoreOnBottomReached]);

  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtualizer({
    count: flatData.length,
    getScrollElement: () => tableContainerRef.current,
    estimateSize: () => estimatedRowHeight,
    overscan: 20,
  });

  const paddingTop =
    !disableVirtualization && rowVirtualizer.getVirtualItems().length > 0
      ? rowVirtualizer.getVirtualItems()?.[0]?.start || 0
      : 0;

  const paddingBottom =
    !disableVirtualization && rowVirtualizer.getVirtualItems().length > 0
      ? rowVirtualizer.getTotalSize() -
        (rowVirtualizer.getVirtualItems()?.[
          rowVirtualizer.getVirtualItems().length - 1
        ]?.end || 0)
      : 0;

  const tableRows = disableVirtualization
    ? rows
    : rowVirtualizer.getVirtualItems();

  if (!isFetching && flatData.length === 0) {
    return <NoData stretch message={noDataMessage} />;
  }

  return (
    <Flex
      bg={bg}
      direction={"column"}
      height={`${height}px`}
      maxWidth={maxWidth}
      width={width}
      rounded={"md"}
      shadow={"sm"}
    >
      <Box
        ref={tableContainerRef}
        onScroll={(e) => fetchMoreOnBottomReached(e.target as HTMLDivElement)}
        style={{
          zIndex: 0,
          overflowX: "auto",
          overflowY: "auto",
          willChange: "scroll-position",
        }}
        className={"InfiniteScrollTable__Wrapper"}
      >
        <ChakraTable
          size="sm"
          variant={tableVariant}
          shadow={"sm"}
          style={{ position: "relative" }}
          {...(disableVirtualization
            ? {}
            : {
                height: `${rowVirtualizer.getTotalSize()}px`,
              })}
        >
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <HeaderGroup
                key={headerGroup.id}
                headerGroup={headerGroup}
                isSticky
              />
            ))}
          </Thead>
          <Tbody>
            {paddingTop > 0 && (
              <Tr>
                <Td
                  colSpan={tableColumns.length + 1}
                  style={{ height: `${paddingTop}px` }}
                />
              </Tr>
            )}
            {tableRows.map((tableRow) => {
              const row = rows[tableRow.index];
              return (
                <Row<T>
                  key={row.id}
                  row={row}
                  renderExpandableRowComponent={renderExpandableRowComponent}
                  colSpan={tableColumns.length + 1}
                  height={`${estimatedRowHeight}px`}
                />
              );
            })}
            {isFetching && (
              <Tr height={`${estimatedRowHeight}px`}>
                <Td colSpan={tableColumns.length + 1}>
                  <Loader minHeight="0px" height="80px" label="" />
                </Td>
              </Tr>
            )}
            {paddingBottom > 0 && (
              <Tr>
                <Td
                  colSpan={tableColumns.length + 1}
                  style={{ height: `${paddingBottom}px` }}
                />
              </Tr>
            )}
          </Tbody>
        </ChakraTable>
      </Box>
    </Flex>
  );
};

export { InfiniteScrollTable };
