/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull web-ampli-wrapper'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 3
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/intentsify/Intentsify/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/intentsify/Intentsify/implementation/web-ampli-wrapper)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'default';

export const ApiKey: Record<Environment, string> = {
  default: 'b75505c3f477d23c4ec5c8cd53f6b1e4'
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '3',
    branch: 'main',
    source: 'web-ampli-wrapper',
    versionId: '33d4a191-c912-4206-bb1f-4f3a0f345a6e'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  }
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  agencyId?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  businessEntityId?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  companyId?: number;
  displayName?: string;
  email?: string;
  /**
   * User's role
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | user, writer, admin, publisher, accessAdmin, unspecified |
   */
  role?: "user" | "writer" | "admin" | "publisher" | "accessAdmin" | "unspecified";
}

export interface CampaignFileDownloadedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | number |
   */
  campaignIds: number[];
  pagePath: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | campaign account mapping, campaign metadata, campaign, campaign details csv, campaign leads, campaign autodiscovery, campaign autodiscovery processed metadata, campaign weekly snapshot, campaign spotlight, campaign wrapup, campaign program review, campaign details with buying group contacts csv, campaign details with buying group, campaign moat, campaign cumulative ad solutions, campaign cumulative intent feed brs, campaign cumulative intent feed topics, campaign cumulative site pixel data, campaign buying group intent feed, campaign lead activation summaries |
   */
  type:
    | "campaign account mapping"
    | "campaign metadata"
    | "campaign"
    | "campaign details csv"
    | "campaign leads"
    | "campaign autodiscovery"
    | "campaign autodiscovery processed metadata"
    | "campaign weekly snapshot"
    | "campaign spotlight"
    | "campaign wrapup"
    | "campaign program review"
    | "campaign details with buying group contacts csv"
    | "campaign details with buying group"
    | "campaign moat"
    | "campaign cumulative ad solutions"
    | "campaign cumulative intent feed brs"
    | "campaign cumulative intent feed topics"
    | "campaign cumulative site pixel data"
    | "campaign buying group intent feed"
    | "campaign lead activation summaries";
}

export interface CarouselSlideChangedProperties {
  collocation: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  slideIndex: number;
}

export interface ChartDownloadedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  campaignId?: number;
  pagePath: string;
  title: string;
}

export interface CompanyPixelCreatedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  companyId: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pixelId?: number;
}

export interface CompanyPixelEditedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  companyId: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pixelId?: number;
}

export interface CompanyPixelScriptCopiedToClipboardProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pixelId?: number;
}

export interface CompanyPixelTrackedWebsiteAddedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pixelId?: number;
}

export interface FileDownloadedProperties {
  fileName: string;
  label: string;
  pagePath: string;
}

export interface RequestReportingProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  campaignId: number;
  pagePath: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | spotlight, wrapup, programReview, moat, cumulativeSitePixelData, cumulativeIntentFeedTopics, cumulativeIntentFeedBrs, cumulativeAdSolutions |
   */
  requestingDeck:
    | "spotlight"
    | "wrapup"
    | "programReview"
    | "moat"
    | "cumulativeSitePixelData"
    | "cumulativeIntentFeedTopics"
    | "cumulativeIntentFeedBrs"
    | "cumulativeAdSolutions";
}

export interface RequestWeeklySnapshotReportProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  campaignId: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  weekFrom?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  weekTo?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  yearFrom?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  yearTo?: number;
}

export interface SearchedProperties {
  collocation: string;
  pagePath: string;
  term: string;
}

export interface SeeMoreTopicSuggestionsProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  campaignId?: number;
}

export interface TabClickedProperties {
  collocation: string;
  name: string;
  pagePath: string;
}

export interface TooltipHoveredProperties {
  label: string;
  pagePath: string;
  screenName: string;
}

export interface TopicSuggestionSelectedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  campaignId?: number;
  topicId: string;
}

export interface WebsiteVisitorAnalyticsAccountViewFilterSelectedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | all_accounts, intent_model |
   */
  accountViewFilterValue: "all_accounts" | "intent_model";
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pixelId?: number;
}

export interface WebsiteVisitorAnalyticsAccountsFilterSelectedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | number |
   */
  accountsIds: number[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pixelId?: number;
}

export interface WebsiteVisitorAnalyticsCumulativeSitePixelReportRequestedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  campaignId?: number;
  endDate: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pixelId?: number;
  startDate: string;
}

export interface WebsiteVisitorAnalyticsDateRangeFilterSelectedProperties {
  endDate: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pixelId?: number;
  startDate: string;
}

export interface WebsiteVisitorAnalyticsIntentModelFilterSelectedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  campaignId?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pixelId?: number;
}

export interface WebsiteVisitorAnalyticsReportDeliveryRecipientCreatedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pixelId?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  recipientId: number;
}

export interface WebsiteVisitorAnalyticsReportDeliveryRecipientDeletedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pixelId?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  recipientId: number;
}

export interface WebsiteVisitorAnalyticsReportDeliveryRecipientEditedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pixelId?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  recipientId: number;
}

export interface WebsiteVisitorAnalyticsViewSelectedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | visualization, list-view |
   */
  selectedView: "visualization" | "list-view";
}

export interface WebsiteVisitorAnalyticsVisitedPageFilterSelectedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pixelId?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  visitedPages: string[];
}

export interface WebsiteVisitorAnalyticsVisitingCompaniesDataExportedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pixelId?: number;
}

export interface WebsiteVisitorAnalyticsVisitingCompaniesRowExpandedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  accountId: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  pixelId?: number;
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY;

  constructor(
    public event_properties?: IdentifyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CampaignFileDownloaded implements BaseEvent {
  event_type = 'Campaign file downloaded';

  constructor(
    public event_properties: CampaignFileDownloadedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CarouselSlideChanged implements BaseEvent {
  event_type = 'Carousel slide changed';

  constructor(
    public event_properties: CarouselSlideChangedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChartDownloaded implements BaseEvent {
  event_type = 'Chart downloaded';

  constructor(
    public event_properties: ChartDownloadedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CompanyPixelCreated implements BaseEvent {
  event_type = 'Company pixel created';

  constructor(
    public event_properties: CompanyPixelCreatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CompanyPixelDeploymentInstructionsDownloaded implements BaseEvent {
  event_type = 'Company pixel deployment instructions downloaded';
}

export class CompanyPixelEdited implements BaseEvent {
  event_type = 'Company pixel edited';

  constructor(
    public event_properties: CompanyPixelEditedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CompanyPixelScriptCopiedToClipboard implements BaseEvent {
  event_type = 'Company pixel script copied to clipboard';

  constructor(
    public event_properties?: CompanyPixelScriptCopiedToClipboardProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CompanyPixelTrackedWebsiteAdded implements BaseEvent {
  event_type = 'Company pixel tracked website added';

  constructor(
    public event_properties?: CompanyPixelTrackedWebsiteAddedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DigitalForecastingRequest implements BaseEvent {
  event_type = 'Digital forecasting request';
}

export class FileDownloaded implements BaseEvent {
  event_type = 'File downloaded';

  constructor(
    public event_properties: FileDownloadedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RequestReporting implements BaseEvent {
  event_type = 'Request reporting';

  constructor(
    public event_properties: RequestReportingProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RequestWeeklySnapshotReport implements BaseEvent {
  event_type = 'requestWeeklySnapshotReport';

  constructor(
    public event_properties: RequestWeeklySnapshotReportProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class Searched implements BaseEvent {
  event_type = 'Searched';

  constructor(
    public event_properties: SearchedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SeeMoreTopicSuggestions implements BaseEvent {
  event_type = 'See more topic suggestions';

  constructor(
    public event_properties?: SeeMoreTopicSuggestionsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TabClicked implements BaseEvent {
  event_type = 'Tab clicked';

  constructor(
    public event_properties: TabClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TooltipHovered implements BaseEvent {
  event_type = 'Tooltip hovered';

  constructor(
    public event_properties: TooltipHoveredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TopicSuggestionSelected implements BaseEvent {
  event_type = 'Topic suggestion selected';

  constructor(
    public event_properties: TopicSuggestionSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WebsiteVisitorAnalyticsAccountViewFilterSelected implements BaseEvent {
  event_type = 'Website visitor analytics account view filter selected';

  constructor(
    public event_properties: WebsiteVisitorAnalyticsAccountViewFilterSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WebsiteVisitorAnalyticsAccountsFilterSelected implements BaseEvent {
  event_type = 'Website visitor analytics accounts filter selected';

  constructor(
    public event_properties: WebsiteVisitorAnalyticsAccountsFilterSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WebsiteVisitorAnalyticsCumulativeSitePixelReportRequested implements BaseEvent {
  event_type = 'Website visitor analytics cumulative site pixel report requested';

  constructor(
    public event_properties: WebsiteVisitorAnalyticsCumulativeSitePixelReportRequestedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WebsiteVisitorAnalyticsDateRangeFilterSelected implements BaseEvent {
  event_type = 'Website visitor analytics date range filter selected';

  constructor(
    public event_properties: WebsiteVisitorAnalyticsDateRangeFilterSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WebsiteVisitorAnalyticsIntentModelFilterSelected implements BaseEvent {
  event_type = 'Website visitor analytics intent model filter selected';

  constructor(
    public event_properties?: WebsiteVisitorAnalyticsIntentModelFilterSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WebsiteVisitorAnalyticsReportDeliveryRecipientCreated implements BaseEvent {
  event_type = 'Website visitor analytics report delivery recipient created';

  constructor(
    public event_properties: WebsiteVisitorAnalyticsReportDeliveryRecipientCreatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WebsiteVisitorAnalyticsReportDeliveryRecipientDeleted implements BaseEvent {
  event_type = 'Website visitor analytics report delivery recipient deleted';

  constructor(
    public event_properties: WebsiteVisitorAnalyticsReportDeliveryRecipientDeletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WebsiteVisitorAnalyticsReportDeliveryRecipientEdited implements BaseEvent {
  event_type = 'Website visitor analytics report delivery recipient edited';

  constructor(
    public event_properties: WebsiteVisitorAnalyticsReportDeliveryRecipientEditedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WebsiteVisitorAnalyticsViewSelected implements BaseEvent {
  event_type = 'Website visitor analytics view selected';

  constructor(
    public event_properties: WebsiteVisitorAnalyticsViewSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WebsiteVisitorAnalyticsVisitedPageFilterSelected implements BaseEvent {
  event_type = 'Website visitor analytics visited page filter selected';

  constructor(
    public event_properties: WebsiteVisitorAnalyticsVisitedPageFilterSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WebsiteVisitorAnalyticsVisitingCompaniesDataExported implements BaseEvent {
  event_type = 'Website visitor analytics visiting companies data exported';

  constructor(
    public event_properties?: WebsiteVisitorAnalyticsVisitingCompaniesDataExportedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WebsiteVisitorAnalyticsVisitingCompaniesRowExpanded implements BaseEvent {
  event_type = 'Website visitor analytics visiting companies row expanded';

  constructor(
    public event_properties: WebsiteVisitorAnalyticsVisitingCompaniesRowExpandedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * Campaign file downloaded
   *
   * [View in Tracking Plan](https://data.amplitude.com/intentsify/Intentsify/events/main/latest/Campaign%20file%20downloaded)
   *
   * This event tracks when a user downloads a campaign file
   *
   * Owner: Kacper Ochmański
   *
   * @param properties The event's properties (e.g. campaignIds)
   * @param options Amplitude event options.
   */
  campaignFileDownloaded(
    properties: CampaignFileDownloadedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CampaignFileDownloaded(properties), options);
  }

  /**
   * Carousel slide changed
   *
   * [View in Tracking Plan](https://data.amplitude.com/intentsify/Intentsify/events/main/latest/Carousel%20slide%20changed)
   *
   * This event is triggered when a user changes the slide in a carousel
   *
   * Owner: Kacper Ochmański
   *
   * @param properties The event's properties (e.g. collocation)
   * @param options Amplitude event options.
   */
  carouselSlideChanged(
    properties: CarouselSlideChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CarouselSlideChanged(properties), options);
  }

  /**
   * Chart downloaded
   *
   * [View in Tracking Plan](https://data.amplitude.com/intentsify/Intentsify/events/main/latest/Chart%20downloaded)
   *
   * This event tracks when a user downloads a chart from the platform
   *
   * Owner: Kacper Ochmański
   *
   * @param properties The event's properties (e.g. campaignId)
   * @param options Amplitude event options.
   */
  chartDownloaded(
    properties: ChartDownloadedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChartDownloaded(properties), options);
  }

  /**
   * Company pixel created
   *
   * [View in Tracking Plan](https://data.amplitude.com/intentsify/Intentsify/events/main/latest/Company%20pixel%20created)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. companyId)
   * @param options Amplitude event options.
   */
  companyPixelCreated(
    properties: CompanyPixelCreatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CompanyPixelCreated(properties), options);
  }

  /**
   * Company pixel deployment instructions downloaded
   *
   * [View in Tracking Plan](https://data.amplitude.com/intentsify/Intentsify/events/main/latest/Company%20pixel%20deployment%20instructions%20downloaded)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  companyPixelDeploymentInstructionsDownloaded(
    options?: EventOptions,
  ) {
    return this.track(new CompanyPixelDeploymentInstructionsDownloaded(), options);
  }

  /**
   * Company pixel edited
   *
   * [View in Tracking Plan](https://data.amplitude.com/intentsify/Intentsify/events/main/latest/Company%20pixel%20edited)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. companyId)
   * @param options Amplitude event options.
   */
  companyPixelEdited(
    properties: CompanyPixelEditedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CompanyPixelEdited(properties), options);
  }

  /**
   * Company pixel script copied to clipboard
   *
   * [View in Tracking Plan](https://data.amplitude.com/intentsify/Intentsify/events/main/latest/Company%20pixel%20script%20copied%20to%20clipboard)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. pixelId)
   * @param options Amplitude event options.
   */
  companyPixelScriptCopiedToClipboard(
    properties?: CompanyPixelScriptCopiedToClipboardProperties,
    options?: EventOptions,
  ) {
    return this.track(new CompanyPixelScriptCopiedToClipboard(properties), options);
  }

  /**
   * Company pixel tracked website added
   *
   * [View in Tracking Plan](https://data.amplitude.com/intentsify/Intentsify/events/main/latest/Company%20pixel%20tracked%20website%20added)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. pixelId)
   * @param options Amplitude event options.
   */
  companyPixelTrackedWebsiteAdded(
    properties?: CompanyPixelTrackedWebsiteAddedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CompanyPixelTrackedWebsiteAdded(properties), options);
  }

  /**
   * Digital forecasting request
   *
   * [View in Tracking Plan](https://data.amplitude.com/intentsify/Intentsify/events/main/latest/Digital%20forecasting%20request)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  digitalForecastingRequest(
    options?: EventOptions,
  ) {
    return this.track(new DigitalForecastingRequest(), options);
  }

  /**
   * File downloaded
   *
   * [View in Tracking Plan](https://data.amplitude.com/intentsify/Intentsify/events/main/latest/File%20downloaded)
   *
   * This event tracks when a file is downloaded by a user
   *
   * @param properties The event's properties (e.g. fileName)
   * @param options Amplitude event options.
   */
  fileDownloaded(
    properties: FileDownloadedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FileDownloaded(properties), options);
  }

  /**
   * Request reporting
   *
   * [View in Tracking Plan](https://data.amplitude.com/intentsify/Intentsify/events/main/latest/Request%20reporting)
   *
   * This event tracks when a user requests a report: Account Spotlight or Wrap Up Review
   *
   * Owner: Kacper Ochmański
   *
   * @param properties The event's properties (e.g. campaignId)
   * @param options Amplitude event options.
   */
  requestReporting(
    properties: RequestReportingProperties,
    options?: EventOptions,
  ) {
    return this.track(new RequestReporting(properties), options);
  }

  /**
   * requestWeeklySnapshotReport
   *
   * [View in Tracking Plan](https://data.amplitude.com/intentsify/Intentsify/events/main/latest/requestWeeklySnapshotReport)
   *
   * This event tracks when a user requests a weekly snapshot report
   *
   * Owner: Kacper Ochmański
   *
   * @param properties The event's properties (e.g. campaignId)
   * @param options Amplitude event options.
   */
  requestWeeklySnapshotReport(
    properties: RequestWeeklySnapshotReportProperties,
    options?: EventOptions,
  ) {
    return this.track(new RequestWeeklySnapshotReport(properties), options);
  }

  /**
   * Searched
   *
   * [View in Tracking Plan](https://data.amplitude.com/intentsify/Intentsify/events/main/latest/Searched)
   *
   * This event tracks when a user performs a search within the application
   *
   * Owner: Kacper Ochmański
   *
   * @param properties The event's properties (e.g. collocation)
   * @param options Amplitude event options.
   */
  searched(
    properties: SearchedProperties,
    options?: EventOptions,
  ) {
    return this.track(new Searched(properties), options);
  }

  /**
   * See more topic suggestions
   *
   * [View in Tracking Plan](https://data.amplitude.com/intentsify/Intentsify/events/main/latest/See%20more%20topic%20suggestions)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. campaignId)
   * @param options Amplitude event options.
   */
  seeMoreTopicSuggestions(
    properties?: SeeMoreTopicSuggestionsProperties,
    options?: EventOptions,
  ) {
    return this.track(new SeeMoreTopicSuggestions(properties), options);
  }

  /**
   * Tab clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/intentsify/Intentsify/events/main/latest/Tab%20clicked)
   *
   * This event tracks when a user clicks on a tab within the application
   *
   * Owner: Kacper Ochmański
   *
   * @param properties The event's properties (e.g. collocation)
   * @param options Amplitude event options.
   */
  tabClicked(
    properties: TabClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TabClicked(properties), options);
  }

  /**
   * Tooltip hovered
   *
   * [View in Tracking Plan](https://data.amplitude.com/intentsify/Intentsify/events/main/latest/Tooltip%20hovered)
   *
   * This event tracks when a user hovers over a tooltip, providing insights into user engagement with tooltips on the platform
   *
   * Owner: Kacper Ochmański
   *
   * @param properties The event's properties (e.g. label)
   * @param options Amplitude event options.
   */
  tooltipHovered(
    properties: TooltipHoveredProperties,
    options?: EventOptions,
  ) {
    return this.track(new TooltipHovered(properties), options);
  }

  /**
   * Topic suggestion selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/intentsify/Intentsify/events/main/latest/Topic%20suggestion%20selected)
   *
   * This event is triggered when a user selects a topic suggestion from a list of suggestions
   *
   * @param properties The event's properties (e.g. campaignId)
   * @param options Amplitude event options.
   */
  topicSuggestionSelected(
    properties: TopicSuggestionSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TopicSuggestionSelected(properties), options);
  }

  /**
   * Website visitor analytics account view filter selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/intentsify/Intentsify/events/main/latest/Website%20visitor%20analytics%20account%20view%20filter%20selected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. accountViewFilterValue)
   * @param options Amplitude event options.
   */
  websiteVisitorAnalyticsAccountViewFilterSelected(
    properties: WebsiteVisitorAnalyticsAccountViewFilterSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WebsiteVisitorAnalyticsAccountViewFilterSelected(properties), options);
  }

  /**
   * Website visitor analytics accounts filter selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/intentsify/Intentsify/events/main/latest/Website%20visitor%20analytics%20accounts%20filter%20selected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. accountsIds)
   * @param options Amplitude event options.
   */
  websiteVisitorAnalyticsAccountsFilterSelected(
    properties: WebsiteVisitorAnalyticsAccountsFilterSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WebsiteVisitorAnalyticsAccountsFilterSelected(properties), options);
  }

  /**
   * Website visitor analytics cumulative site pixel report requested
   *
   * [View in Tracking Plan](https://data.amplitude.com/intentsify/Intentsify/events/main/latest/Website%20visitor%20analytics%20cumulative%20site%20pixel%20report%20requested)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. campaignId)
   * @param options Amplitude event options.
   */
  websiteVisitorAnalyticsCumulativeSitePixelReportRequested(
    properties: WebsiteVisitorAnalyticsCumulativeSitePixelReportRequestedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WebsiteVisitorAnalyticsCumulativeSitePixelReportRequested(properties), options);
  }

  /**
   * Website visitor analytics date range filter selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/intentsify/Intentsify/events/main/latest/Website%20visitor%20analytics%20date%20range%20filter%20selected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. endDate)
   * @param options Amplitude event options.
   */
  websiteVisitorAnalyticsDateRangeFilterSelected(
    properties: WebsiteVisitorAnalyticsDateRangeFilterSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WebsiteVisitorAnalyticsDateRangeFilterSelected(properties), options);
  }

  /**
   * Website visitor analytics intent model filter selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/intentsify/Intentsify/events/main/latest/Website%20visitor%20analytics%20intent%20model%20filter%20selected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. campaignId)
   * @param options Amplitude event options.
   */
  websiteVisitorAnalyticsIntentModelFilterSelected(
    properties?: WebsiteVisitorAnalyticsIntentModelFilterSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WebsiteVisitorAnalyticsIntentModelFilterSelected(properties), options);
  }

  /**
   * Website visitor analytics report delivery recipient created
   *
   * [View in Tracking Plan](https://data.amplitude.com/intentsify/Intentsify/events/main/latest/Website%20visitor%20analytics%20report%20delivery%20recipient%20created)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. pixelId)
   * @param options Amplitude event options.
   */
  websiteVisitorAnalyticsReportDeliveryRecipientCreated(
    properties: WebsiteVisitorAnalyticsReportDeliveryRecipientCreatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WebsiteVisitorAnalyticsReportDeliveryRecipientCreated(properties), options);
  }

  /**
   * Website visitor analytics report delivery recipient deleted
   *
   * [View in Tracking Plan](https://data.amplitude.com/intentsify/Intentsify/events/main/latest/Website%20visitor%20analytics%20report%20delivery%20recipient%20deleted)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. pixelId)
   * @param options Amplitude event options.
   */
  websiteVisitorAnalyticsReportDeliveryRecipientDeleted(
    properties: WebsiteVisitorAnalyticsReportDeliveryRecipientDeletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WebsiteVisitorAnalyticsReportDeliveryRecipientDeleted(properties), options);
  }

  /**
   * Website visitor analytics report delivery recipient edited
   *
   * [View in Tracking Plan](https://data.amplitude.com/intentsify/Intentsify/events/main/latest/Website%20visitor%20analytics%20report%20delivery%20recipient%20edited)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. pixelId)
   * @param options Amplitude event options.
   */
  websiteVisitorAnalyticsReportDeliveryRecipientEdited(
    properties: WebsiteVisitorAnalyticsReportDeliveryRecipientEditedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WebsiteVisitorAnalyticsReportDeliveryRecipientEdited(properties), options);
  }

  /**
   * Website visitor analytics view selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/intentsify/Intentsify/events/main/latest/Website%20visitor%20analytics%20view%20selected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. selectedView)
   * @param options Amplitude event options.
   */
  websiteVisitorAnalyticsViewSelected(
    properties: WebsiteVisitorAnalyticsViewSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WebsiteVisitorAnalyticsViewSelected(properties), options);
  }

  /**
   * Website visitor analytics visited page filter selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/intentsify/Intentsify/events/main/latest/Website%20visitor%20analytics%20visited%20page%20filter%20selected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. pixelId)
   * @param options Amplitude event options.
   */
  websiteVisitorAnalyticsVisitedPageFilterSelected(
    properties: WebsiteVisitorAnalyticsVisitedPageFilterSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WebsiteVisitorAnalyticsVisitedPageFilterSelected(properties), options);
  }

  /**
   * Website visitor analytics visiting companies data exported
   *
   * [View in Tracking Plan](https://data.amplitude.com/intentsify/Intentsify/events/main/latest/Website%20visitor%20analytics%20visiting%20companies%20data%20exported)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. pixelId)
   * @param options Amplitude event options.
   */
  websiteVisitorAnalyticsVisitingCompaniesDataExported(
    properties?: WebsiteVisitorAnalyticsVisitingCompaniesDataExportedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WebsiteVisitorAnalyticsVisitingCompaniesDataExported(properties), options);
  }

  /**
   * Website visitor analytics visiting companies row expanded
   *
   * [View in Tracking Plan](https://data.amplitude.com/intentsify/Intentsify/events/main/latest/Website%20visitor%20analytics%20visiting%20companies%20row%20expanded)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. accountId)
   * @param options Amplitude event options.
   */
  websiteVisitorAnalyticsVisitingCompaniesRowExpanded(
    properties: WebsiteVisitorAnalyticsVisitingCompaniesRowExpandedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WebsiteVisitorAnalyticsVisitingCompaniesRowExpanded(properties), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
