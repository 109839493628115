import { SegmentState, SegmentType } from "@intentsify/types";

export const getSegmentUploadState = (
  state: SegmentType,
  ipAddressSegmentState: SegmentState,
  userIdSegmentState: SegmentState,
  maidsSegmentState: SegmentState
) => {
  switch (state) {
    case SegmentType.IP:
      return ipAddressSegmentState;
    case SegmentType.USER_ID:
      return userIdSegmentState;
    case SegmentType.MAID:
      return maidsSegmentState;
    default:
      return SegmentState.STATE_UNSPECIFIED;
  }
};
