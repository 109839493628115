import { Box, Flex, Text } from "@chakra-ui/layout";
import { CampaignMeta } from "@intentsify/types";
import { pluralize, toNumberDisplayValue } from "@intentsify/utils";
import { StatCard, StatCardsContainer } from "components";
import { listFormat } from "shared/listFormat";
import { useBuyingGroupInsight } from "../useBuyingGroupInsight";
import { AccountsTable } from "./AccountsTable";
import { BuyingGroupContactByJobFunction } from "./BuyingGroupContactByJobFunction";
import { BuyingGroupContactByJobLevel } from "./BuyingGroupContactByJobLevel";
import { BuyingGroupContactCountDistribution } from "./BuyingGroupContactCountDistribution";

interface BuyingGroupInsightsProps {
  campaignId: number;
  weekNumber: number;
  yearNumber: number;
  campaignMeta?: CampaignMeta;
}

export function BuyingGroupInsights(props: BuyingGroupInsightsProps) {
  const buyingGroupInsight = useBuyingGroupInsight(props.campaignId);

  if (!buyingGroupInsight.data) {
    return (
      <Box>
        <Flex
          alignItems="center"
          justifyContent="center"
          flexDir="column"
          gap="1"
          mt="12"
          w="100%"
        >
          <Text fontSize="2xl">Buying group is not configured</Text>
          <Text color="blackAlpha.700" _dark={{ color: "whiteAlpha.700" }}>
            Reach out to your Sales rep to upgrade your package.
          </Text>
        </Flex>
      </Box>
    );
  }

  return (
    <Box width={"full"}>
      <Box px={4} mt={4}>
        <InfoCards campaignId={props.campaignId} />
      </Box>

      <Box px={4} mt={4} pb={24}>
        <AccountsTable
          campaignId={props.campaignId}
          weekNumber={props.weekNumber}
          yearNumber={props.yearNumber}
          campaignMeta={props.campaignMeta}
        />
      </Box>
    </Box>
  );
}

interface InfoCardsProps {
  campaignId: number;
}

function InfoCards(props: InfoCardsProps) {
  const buyingGroupInsight = useBuyingGroupInsight(props.campaignId);

  return (
    <Flex flexDir="column">
      <StatCardsContainer
        items={[
          {
            isLoading: buyingGroupInsight.isFetching,
            title: "Total active accounts",
            value: buyingGroupInsight.data?.ad_cnt,
            valueFormatter: (value) => `${toNumberDisplayValue(value)}`,
            titleTooltip:
              "Accounts from your target account list showing intent activity and research relevant to the campaign.",
            noValueMessage: "No historical data available.",
          },
          {
            isLoading: buyingGroupInsight.isFetching,
            title: "Active accounts with Buying Groups",
            value: buyingGroupInsight.data?.act_acc_cont,
            valueFormatter: (value) => `${toNumberDisplayValue(value)}`,
            noValueMessage: "No historical data available.",
          },
          {
            isLoading: buyingGroupInsight.isFetching,
            title: "Buying Group Contacts for Active accounts",
            value: buyingGroupInsight.data?.total_cont,
            valueFormatter: (value) => `${toNumberDisplayValue(value)}`,
            noValueMessage: "No historical data available.",
          },
          {
            isLoading: buyingGroupInsight.isFetching,
            title: "Buying Group details",
            value: buyingGroupInsight.data?.details.tp,
            valueFormatter: (value) => `${toNumberDisplayValue(value)}`,
            noValueMessage: "No historical data available.",
            description: pluralize(
              buyingGroupInsight.data?.details.tp ?? 0,
              "Target persona"
            ),
            caption: buyingGroupInsight.data?.details.list
              ? `Matching personas: ${listFormat.format(
                  // TODO: this will be fixed by ts5.5
                  (
                    buyingGroupInsight.data?.details.list as unknown as string[]
                  ).filter(Boolean)
                )}`
              : "",
          },
        ]}
      />

      <Flex wrap={"wrap"} gap="4" mb={4} w="100%">
        <Flex flex={1} minW="240px">
          <StatCard
            isLoading={buyingGroupInsight.isFetching}
            title="Buying Group Contact Coverage"
            value={buyingGroupInsight.data?.coverage}
            description="of active accounts that have at least 1 buying group contact"
            valueFormatter={(value) =>
              `${toNumberDisplayValue(value * 100, { compact: true })}%`
            }
          />
        </Flex>

        <Flex flex={1}>
          <BuyingGroupContactCountDistribution campaignId={props.campaignId} />
        </Flex>

        <Flex flex={1}>
          <BuyingGroupContactByJobLevel campaignId={props.campaignId} />
        </Flex>

        <Flex flex={1}>
          <BuyingGroupContactByJobFunction campaignId={props.campaignId} />
        </Flex>
      </Flex>
    </Flex>
  );
}
