import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router";
import {
  createCompanyPixel,
  deleteCompanyPixel,
  editCompanyPixel,
} from "./PixelForm.requests";
import {
  EditPixelScreenDefinition,
  PageTrackingScreenDefinition,
} from "screens";
import { MutateCompanyPixelDTO } from "@intentsify/dto";
import { ampli } from "../../../../../tracking/amplitude";

const useCreateCompanyPixel = (companyId: number) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { mutate, isLoading, data } = useMutation({
    mutationFn: async (params: MutateCompanyPixelDTO) => {
      return createCompanyPixel(companyId, params);
    },
    onSuccess: async (data) => {
      toast({
        title: "Pixel was created successfully.",
        status: "success",
        duration: 9000,
      });
      await queryClient.invalidateQueries(["listCompanyPixels", companyId]);
      ampli.companyPixelCreated({
        companyId,
        pixelId: data.companyPixel.pixelId,
      });
      navigate(
        EditPixelScreenDefinition.navigate({
          pixelId: data.companyPixel.pixelId,
        })
      );
    },
  });

  return { createCompanyPixel: mutate, isLoading, data };
};

const useEditCompanyPixel = (companyId: number, pixelId: number) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation({
    mutationFn: async (params: MutateCompanyPixelDTO) => {
      await editCompanyPixel(companyId, pixelId, params);
    },
    onSuccess: async () => {
      toast({
        title: "Pixel was updated successfully.",
        status: "success",
        duration: 9000,
      });
      await queryClient.invalidateQueries(["getCompanyPixel", pixelId]);
      await queryClient.invalidateQueries(["listCompanyPixels", companyId]);
      ampli.companyPixelEdited({ companyId, pixelId });
      navigate(PageTrackingScreenDefinition.navigate());
    },
  });

  return { editCompanyPixel: mutate, isLoading };
};

const useDeleteCompanyPixel = (companyId: number, pixelId: number) => {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: async () => {
      await deleteCompanyPixel(companyId, pixelId);
    },
    onSuccess: async () => {
      toast({
        title: "Pixel was deleted.",
        status: "success",
        duration: 9000,
      });
      await queryClient.invalidateQueries(["listCompanyPixels", companyId]);
    },
  });

  return { deleteCompanyPixel: mutate, isLoading };
};

export { useCreateCompanyPixel, useEditCompanyPixel, useDeleteCompanyPixel };
