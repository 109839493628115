import { Box, Flex, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import {
  HasAccess,
  useHasAccessTo,
} from "@intentsify/authorization/dist/react";
import { WeeklySnapshotRangeDTO } from "@intentsify/dto";
import { toNumberDisplayValue } from "@intentsify/utils";
import {
  ButtonGroup,
  ButtonGroupItem,
  Loader,
  StatCardsContainer,
} from "components";
import { WeekPicker } from "components/DatePicker/WeekPicker";
import { DateTime } from "luxon";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { RequestReportButton } from "screens/IntentAnalysis/screens/WeeklySnapshot/components/RequestReportButton/RequestReportButton";
import {
  CampaignActionsItems,
  CampaignMetaHeader,
  useCampaignMeta,
} from "shared/components";
import { SelectedWeek } from "types";
import { useDeepEffect, useScreen } from "utils";
import { BuyingGroupInsights } from "./BuyingGroupInsights/BuyingGroupInsights";
import { CampaignDetailsScreenDefinition } from "./CampaignDetails.definition";
import { useCampaignSummary } from "./CampaignDetails.hooks";
import { CampaignDetailsTable } from "./CampaignDetailsTable";
import { WeeklySnapshot } from "./components/WeeklySnapshot";
import { useBuyingGroupInsight } from "./useBuyingGroupInsight";

enum CampaignDetailsView {
  WeeklySnapshot = "weekly-snapshot",
  AccountDetails = "account-details",
  BuyingGroupInsights = "buying-group-insights",
}

const getLastWeek = () => ({
  start: DateTime.now().minus({ weeks: 1 }).startOf("week").toJSDate(),
  end: DateTime.now().minus({ weeks: 1 }).endOf("week").toJSDate(),
});

const CampaignDetails = () => {
  const topbarColor = useColorModeValue("white", "brand.375");
  const hasAccessToBuyingGroupInsights = useHasAccessTo("buyingGroup.insights");

  useScreen(CampaignDetailsScreenDefinition);
  const navigate = useNavigate();

  const { campaignId: id, view } = useParams<"campaignId" | "view">();

  if (!id) {
    throw new Error("404");
  }

  const campaignId = Number(id);

  const buyingGroupInsight = useBuyingGroupInsight(campaignId);

  const selectedView =
    (view as CampaignDetailsView | undefined) ||
    CampaignDetailsView.WeeklySnapshot;

  const lastWeek = getLastWeek();

  const [selectedWeek, setSelectedWeek] = useState<SelectedWeek>({
    weekNumber: DateTime.fromJSDate(lastWeek.end).weekNumber,
    yearNumber: DateTime.fromJSDate(lastWeek.end).year,
  });

  const [createDate, setCreateDate] = useState<SelectedWeek>({
    weekNumber: DateTime.fromJSDate(lastWeek.start).weekNumber,
    yearNumber: DateTime.fromJSDate(lastWeek.start).year,
  });

  const defaultFiltersWeeklySnapshot = {
    weekFrom: DateTime.fromJSDate(lastWeek.start).weekNumber,
    yearFrom: DateTime.fromJSDate(lastWeek.start).year,
    weekTo: DateTime.fromJSDate(lastWeek.end).weekNumber,
    yearTo: DateTime.fromJSDate(lastWeek.end).year,
  };

  const { campaignMetaIsLoading, campaignMeta } = useCampaignMeta({
    campaignId,
    date: {
      weekNumber: selectedWeek.weekNumber,
      yearNumber: selectedWeek.yearNumber,
    },
  });

  useDeepEffect(() => {
    if (campaignMeta) {
      setCreateDate({
        weekNumber: DateTime.fromISO(campaignMeta?.campaignCreateDate)
          .weekNumber,
        yearNumber: DateTime.fromISO(campaignMeta?.campaignCreateDate).year,
      });

      setWeeklySnapshotFilters({
        weekFrom: createDate.weekNumber,
        yearFrom: createDate.yearNumber,
        weekTo: DateTime.fromJSDate(lastWeek.end).weekNumber,
        yearTo: DateTime.fromJSDate(lastWeek.end).year,
      });
    }
  }, [
    campaignMeta,
    createDate.weekNumber,
    createDate.yearNumber,
    lastWeek.start,
  ]);

  const [weeklySnapshotFilters, setWeeklySnapshotFilters] =
    useState<WeeklySnapshotRangeDTO>(defaultFiltersWeeklySnapshot);

  const { data: campaignSummary, isFetching: isFetchingCampaignSummary } =
    useCampaignSummary({
      campaignId,
      date: {
        weekNumber: selectedWeek.weekNumber,
        yearNumber: selectedWeek.yearNumber,
      },
    });

  const handleOnWeekChange = ({
    weekNumber,
    yearNumber,
  }: {
    weekNumber: number;
    yearNumber: number;
  }) => {
    setSelectedWeek({ weekNumber, yearNumber });
    setWeeklySnapshotFilters({
      weekFrom: createDate.weekNumber,
      yearFrom: createDate.yearNumber,
      weekTo: weekNumber,
      yearTo: yearNumber,
    });
  };

  const { weekNumber, yearNumber } = selectedWeek;

  if (campaignMetaIsLoading) {
    return <Loader />;
  }

  return (
    <Flex flexDirection="column">
      <Flex p={4} flexDir="column" bg={topbarColor} gap="10px">
        <Flex justifyContent="space-between" alignItems="center">
          <Flex flexDir="column">
            <CampaignMetaHeader date={{ weekNumber, yearNumber }} />
          </Flex>
        </Flex>

        <Flex
          flexDir="row"
          justifyContent="space-between"
          gap="10px"
          maxWidth="100%"
          sx={{
            "@media(max-width: 450px)": {
              flexDirection: "column",
              gap: "15px",
            },
          }}
        >
          <Stack minW="250px">
            <Text fontSize="xs" fontWeight="semibold">
              Date range:
            </Text>

            <WeekPicker
              onChange={handleOnWeekChange}
              selected={DateTime.fromObject({
                weekNumber: selectedWeek.weekNumber,
                weekYear: selectedWeek.yearNumber,
              }).toISO()}
              disabledDays={
                campaignMeta
                  ? [
                      {
                        gt: DateTime.fromISO(
                          campaignMeta?.campaignStartDate
                        ).startOf("week"),
                        lt: DateTime.fromJSDate(lastWeek.end).endOf("week"),
                      },
                    ]
                  : undefined
              }
              isDisabled={!campaignMeta}
            />
          </Stack>

          <Flex alignItems="end" gap={1}>
            <HasAccess to="campaign.download">
              {selectedView === CampaignDetailsView.WeeklySnapshot && (
                <RequestReportButton
                  campaignId={campaignId}
                  isCampaignSelected={true}
                  filters={weeklySnapshotFilters}
                />
              )}
            </HasAccess>
            <CampaignActionsItems
              isLoading={campaignMetaIsLoading}
              campaignId={campaignId}
              companyId={campaignMeta?.companyId}
              campaignCreatedDate={campaignMeta?.campaignCreateDate}
              hasBeenMappedToConvertr={Boolean(campaignMeta?.bookedLeads)}
              hasLeads={Boolean(campaignMeta?.numberOfLeads)}
              campaignStatusId={campaignMeta?.campaignStatusId}
              hasSpotlight={campaignMeta?.hasSpotlight}
              numberOfVisibleItems={1}
              showCampaignDetailsAction={false}
              hasRelatedProgrammaticCampaigns={Boolean(
                campaignMeta?.hasRelatedProgrammaticCampaigns
              )}
              buttonsVariant={"outline-teal"}
              iconsOnly={false}
              hasPixel={Boolean(campaignMeta?.hasPixel)}
              campaignStartDate={campaignMeta?.campaignStartDate}
              campaignEndDate={campaignMeta?.campaignEndDate ?? undefined}
            />
          </Flex>
        </Flex>
      </Flex>

      <Flex p={4} flexDirection="column">
        <ButtonGroup
          selectedValue={selectedView}
          setSelectedValue={(view) => {
            navigate(
              CampaignDetailsScreenDefinition.navigate({
                campaignId: campaignId,
                view,
              })
            );
          }}
          items={
            [
              {
                label: "Weekly Snapshot",
                value: CampaignDetailsView.WeeklySnapshot,
              },
              {
                label: "Account Details",
                value: CampaignDetailsView.AccountDetails,
              },
              hasAccessToBuyingGroupInsights && {
                label: "Buying Group Insights",
                value: CampaignDetailsView.BuyingGroupInsights,
                isLoading: buyingGroupInsight.isFetching,
              },
            ].filter(Boolean) as ButtonGroupItem<string>[]
          }
        />
      </Flex>

      {selectedView === CampaignDetailsView.AccountDetails && (
        <>
          <Box width={"full"}>
            <Box px={4} mt={4}>
              <StatCardsContainer
                items={[
                  {
                    isLoading: isFetchingCampaignSummary,
                    title: "Total active accounts",
                    value: campaignSummary?.totalAccountsTrending,
                    previousPeriodChange:
                      campaignSummary?.totalAccountsTrendingVsPrevious,
                    previousPeriodLabel: "vs Previous Week:",
                    valueFormatter: (value) => `${toNumberDisplayValue(value)}`,
                    titleTooltip:
                      "Accounts from your target account list showing intent activity and research relevant to the campaign.",
                    noValueMessage: "No historical data available.",
                  },
                  {
                    isLoading: isFetchingCampaignSummary,
                    title:
                      "Total Accounts with Buyer Research Stage Progression",
                    value: campaignSummary?.totalAccountsMovedUp,
                    previousPeriodChange:
                      campaignSummary?.totalAccountsMovedUpVsPrevious,
                    previousPeriodLabel: "vs Previous Week:",
                    valueFormatter: (value) => `${toNumberDisplayValue(value)}`,
                    titleTooltip:
                      "In the previous week, this is the count of active accounts that have moved up in tier (stage of an account) based on intent activity and engagement.",
                    noValueMessage: "No historical data available.",
                  },
                  {
                    isLoading: isFetchingCampaignSummary,
                    title: "Unique Trending Topics",
                    value: campaignSummary?.totalUniqueTopicsTrending,
                    previousPeriodChange:
                      campaignSummary?.totalUniqueTopicsTrendingVsPrevious,
                    previousPeriodLabel: "vs Previous Week:",
                    valueFormatter: (value) => `${toNumberDisplayValue(value)}`,
                    titleTooltip:
                      "In the previous week, unique topics showing active intent signals.",
                    noValueMessage: "No historical data available.",
                  },
                  {
                    isLoading: isFetchingCampaignSummary,
                    title: "Total Unique Data Sources",
                    value: campaignSummary?.totalUniqueSignalSources,
                    previousPeriodChange:
                      campaignSummary?.totalUniqueSignalSourcesVsPrevious,
                    previousPeriodLabel: "vs Previous Week:",
                    valueFormatter: (value) => `${toNumberDisplayValue(value)}`,
                    titleTooltip:
                      "Count of unique intent data sources across your live campaigns. A data source is the channel/origin where intent and research activity take place and is collected from.",
                    noValueMessage: "No historical data available.",
                  },
                ]}
              />
            </Box>
            <CampaignDetailsTable
              campaignId={campaignId}
              weekNumber={weekNumber}
              yearNumber={yearNumber}
              campaignMeta={campaignMeta}
            />
          </Box>
        </>
      )}

      {selectedView === CampaignDetailsView.WeeklySnapshot && (
        <WeeklySnapshot
          campaignId={campaignId}
          weeklySnapshotFilters={weeklySnapshotFilters}
        />
      )}

      {selectedView === CampaignDetailsView.BuyingGroupInsights && (
        <BuyingGroupInsights
          campaignId={campaignId}
          weekNumber={weekNumber}
          yearNumber={yearNumber}
          campaignMeta={campaignMeta}
        />
      )}
    </Flex>
  );
};

export { CampaignDetails };
