import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Stack,
  useToast,
} from "@chakra-ui/react";
import {
  Button,
  ErrorBoundary,
  Modal,
  RequestOnDemandCumulativeReport,
  requestOnDemandCumulativeReportSchema,
  FilterAsyncTokenBased,
} from "components";
import { useRequestCumulativeCampaignReport } from "../../../../../../mutations/campaigns/useRequestCumulativeCampaignReport";
import {
  Nullable,
  Option,
  ReportType,
  ReportTypeLabel,
} from "@intentsify/types";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useComponentColors } from "theme";
import { defaultListPixelsParams, listCampaigns } from "screens";
import { ampli } from "../../../../../../tracking/amplitude";

export const CumulativeSitePixelReportModal = ({
  companyId,
  pixelId,
  isOpen,
  onClose,
  startDate,
  endDate,
}: {
  companyId: number;
  pixelId: number;
  isOpen: boolean;
  onClose: () => void;
  startDate: string;
  endDate: string;
}) => {
  const toast = useToast();
  const componentColors = useComponentColors();

  const [selectedIntentModel, setSelectedIntentModel] =
    useState<Nullable<Option<number>>>(null);

  const {
    formState: { errors },
    control,
    getValues,
    resetField,
    watch,
  } = useForm<RequestOnDemandCumulativeReport>({
    mode: "onChange",
    resolver: zodResolver(requestOnDemandCumulativeReportSchema),
    defaultValues: {
      type: ReportType.CumulativeSitePixelData,
      selectedDate: {
        startDate,
        endDate,
      },
    },
  });

  const campaignId = watch("campaignId");

  const { mutate: requestOnDemandCumulativeReportMutation, isLoading } =
    useRequestCumulativeCampaignReport({
      onSuccess: () => {
        toast({
          title: "Report requested.",
          status: "success",
        });
      },
    });

  return (
    <Modal
      overflow="visible"
      title={ReportTypeLabel[ReportType.CumulativeSitePixelData]}
      isOpen={isOpen}
      onClose={() => {
        resetField("campaignId");
        setSelectedIntentModel(null);
        onClose();
      }}
      size="2xl"
      body={
        <Flex pb="4">
          <ErrorBoundary>
            <Stack width="100%">
              <FormControl
                isInvalid={Boolean(errors.campaignId?.message)}
                isRequired
              >
                <FormLabel
                  color={componentColors.form.formLabelColor}
                  fontSize="sm"
                  fontWeight="bold"
                >
                  Intent Model
                </FormLabel>
                <Controller
                  name="campaignId"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <FilterAsyncTokenBased
                      label={"Intent Model"}
                      defaultOptions={[]}
                      onFilterValuesChange={(e) => {
                        setSelectedIntentModel(e || null);
                        onChange(e?.value || null);
                      }}
                      currentValue={selectedIntentModel}
                      dataRequest={listCampaigns(
                        Number(companyId),
                        Number(pixelId)
                      )}
                      isMulti={false}
                      showLabel={false}
                      params={{
                        ...defaultListPixelsParams,
                      }}
                      resetFilterRequestDependencies={[companyId, pixelId]}
                    />
                  )}
                />

                <FormErrorMessage>
                  {errors.campaignId?.message && "Intent Model is required"}
                </FormErrorMessage>
              </FormControl>
            </Stack>
          </ErrorBoundary>
        </Flex>
      }
      primaryButton={
        <Button
          variant="primary-teal"
          isLoading={isLoading}
          isDisabled={!campaignId}
          onClick={() => {
            const { campaignId, type, selectedDate } = getValues();
            requestOnDemandCumulativeReportMutation({
              id: campaignId,
              params: {
                type,
                startDate: selectedDate.startDate,
                endDate: selectedDate.endDate,
              },
            });
            ampli.websiteVisitorAnalyticsCumulativeSitePixelReportRequested({
              pixelId,
              startDate,
              endDate,
            });
            resetField("campaignId");
            setSelectedIntentModel(null);
            onClose();
          }}
        >
          Process
        </Button>
      }
    />
  );
};
