/* eslint-disable react-hooks/rules-of-hooks */
import { Box, Center, Flex, Link, Text } from "@chakra-ui/layout";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react";
import { useHasAccessTo } from "@intentsify/authorization/dist/react";
import { BaseTargetPersona, TargetPersona } from "@intentsify/types";
import { ColumnDef } from "@tanstack/react-table";
import { Button, IndeterminateCheckbox } from "components";
import { useConfirm } from "components/ConfirmDialog/useConfirm";
import { DelayedTooltip } from "components/Tooltip/DelayedTooltip";
import { DateTime } from "luxon";
import { useCallback, useMemo } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import { FiMoreVertical } from "react-icons/fi";
import { Link as ReactRouterLink } from "react-router-dom";
import { CampaignDetailsScreenDefinition } from "screens/Campaigns";
import { listFormat } from "shared/listFormat";
import { useUser } from "store/store.hooks";
import { TargetPersonasForm } from "./Form";
import {
  TargetPersonasModal,
  TargetPersonasModalBody,
  TargetPersonasModalHeader,
} from "./Modal";
import { useDeleteTargetPersonas } from "./useDeleteTargetPersonas";
import { useUpdateTargetPersonas } from "./useUpdateTargetPersonas";

export const useTargetPersonaColumns = () => {
  const getIsUpdatedByCurrentUser = useGetIsUpdatedByCurrentUser();
  const hasAccessToJobTitleKeywords = useHasAccessTo(
    "targetPersona.jobTitleKeywords"
  );

  return useMemo<ColumnDef<TargetPersona>[]>(
    () => [
      {
        id: "select",
        header: ({ table }) => (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        ),
      },
      {
        id: "actions",
        cell: ({ row }) => {
          const disclosure = useDisclosure();
          const deleteTargetPersonas = useDeleteTargetPersonas();
          const updateTargetPersonas = useUpdateTargetPersonas(
            disclosure.onClose
          );
          const confirm = useConfirm();

          return (
            <Box mx="-20px">
              <Menu matchWidth={false}>
                <DelayedTooltip
                  aria-label="Target persona actions"
                  label="Edit or delete target persona"
                >
                  <MenuButton
                    as={Button}
                    isLoading={deleteTargetPersonas.isLoading}
                    variant="ghost"
                    p="2"
                    m="0"
                    h="auto"
                    size="sm"
                  >
                    <Center gap="1">
                      <FiMoreVertical />
                    </Center>
                  </MenuButton>
                </DelayedTooltip>

                <MenuList>
                  <MenuItem
                    as={Button}
                    justifyContent="flex-start"
                    variant="ghost"
                    bg="transparent"
                    aria-label="Delete target persona"
                    color="red.500"
                    minW="auto"
                    _dark={{ color: "red.100" }}
                    leftIcon={<FaTrash />}
                    isLoading={deleteTargetPersonas.isLoading}
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onClick={async () => {
                      if (
                        !(await confirm({
                          title: "Delete target persona",
                          description: `Are you sure you want to delete ${row.original.name}?`,
                          confirmText: "Delete",
                        }))
                      ) {
                        return;
                      }

                      deleteTargetPersonas.mutate([row.original.id]);
                    }}
                  >
                    Delete
                  </MenuItem>

                  <MenuItem
                    as={Button}
                    justifyContent="flex-start"
                    variant="ghost"
                    bg="transparent"
                    aria-label="Edit target persona"
                    minW="auto"
                    leftIcon={<FaEdit />}
                    onClick={disclosure.onOpen}
                  >
                    Edit
                  </MenuItem>
                </MenuList>
              </Menu>

              <TargetPersonasModal {...disclosure}>
                <TargetPersonasModalHeader>
                  Edit {row.original.name}
                </TargetPersonasModalHeader>

                <TargetPersonasModalBody>
                  <TargetPersonasForm
                    isLoading={updateTargetPersonas.isLoading}
                    defaultValues={row.original}
                    onSubmit={(data) => {
                      updateTargetPersonas.mutate([
                        {
                          ...data,
                          id: row.original.id,
                        },
                      ]);
                    }}
                  />
                </TargetPersonasModalBody>
              </TargetPersonasModal>
            </Box>
          );
        },
      },
      ...(baseTargetPersonaColumns as ColumnDef<TargetPersona>[]).filter(
        (c) => {
          if (!hasAccessToJobTitleKeywords) {
            return c.id !== "jobTitleKeywords";
          }

          return true;
        }
      ),
      {
        header: "Intent model(s)",
        accessorKey: "campaigns",
        enableSorting: false,
        cell: ({ row }) => {
          if (row.original.campaigns.length === 0) {
            return <Text>-</Text>;
          }

          return (
            <Flex maxHeight="100px" overflow="auto" lineHeight={1.5}>
              {row.original.campaigns.map((campaign) => (
                <Center
                  key={campaign.id}
                  borderRadius="md"
                  bg="blackAlpha.100"
                  _dark={{ bg: "brand.450" }}
                >
                  <Link
                    p="1"
                    w="max-content"
                    as={ReactRouterLink}
                    to={CampaignDetailsScreenDefinition.navigate({
                      campaignId: campaign.id,
                      view: undefined,
                    })}
                  >
                    ({campaign.id}) {campaign.name}
                  </Link>
                </Center>
              ))}
            </Flex>
          );
        },
      },
      {
        header: "Created at",
        accessorKey: "createdAt",
        cell: ({ row }) => (
          <Text>{DateTime.fromISO(row.original.createdAt).toRelative()}</Text>
        ),
      },
      {
        header: "Created by",
        accessorKey: "createdBy",
        cell: ({ row }) => {
          if (getIsUpdatedByCurrentUser(row.original.createdBy)) {
            return <Text>{row.original.createdByDisplayName} (You)</Text>;
          }

          return <Text>{row.original.createdByDisplayName}</Text>;
        },
      },
      {
        header: "Updated at",
        accessorKey: "updatedAt",
        cell: ({ row }) => (
          <Text>
            {row.original.updatedAt
              ? DateTime.fromISO(row.original.updatedAt).toRelative()
              : "-"}
          </Text>
        ),
      },
      {
        header: "Updated by",
        accessorKey: "updatedBy",
        cell: ({ row }) => {
          if (
            row.original.updatedBy &&
            getIsUpdatedByCurrentUser(row.original.updatedBy)
          ) {
            return <Text>{row.original.updatedByDisplayName} (You)</Text>;
          }

          return <Text>{row.original.updatedByDisplayName ?? "-"}</Text>;
        },
      },
    ],
    [getIsUpdatedByCurrentUser, hasAccessToJobTitleKeywords]
  );
};

export const baseTargetPersonaColumns: ColumnDef<BaseTargetPersona>[] = [
  {
    header: "Persona name",
    accessorKey: "name",
    cell: ({ row }) => {
      return <Text>{row.original.name}</Text>;
    },
  },
  {
    header: "Buyer role(s)",
    accessorKey: "buyerRoles",
    enableSorting: false,
    cell: ({ row }) => {
      return (
        <Text maxHeight="100px" overflow="auto" lineHeight={1.5}>
          {listFormat.format(row.original.buyerRoles)}
        </Text>
      );
    },
  },
  {
    header: "Job level(s)",
    accessorKey: "jobLevels",
    enableSorting: false,
    cell: ({ row }) => {
      return (
        <Text maxHeight="100px" overflow="auto" lineHeight={1.5}>
          {listFormat.format(row.original.jobLevels.map((j) => j.name))}
        </Text>
      );
    },
  },
  {
    header: "Job function(s)",
    accessorKey: "jobFunctions",
    enableSorting: false,
    cell: ({ row }) => {
      return (
        <Text maxHeight="100px" overflow="auto" lineHeight={1.5}>
          {listFormat.format(row.original.jobFunctions.map((j) => j.name))}
        </Text>
      );
    },
  },
  {
    header: "Job title(s)",
    accessorKey: "jobTitles",
    enableSorting: false,
    cell: ({ row }) => {
      return (
        <Box maxHeight="100px" overflow="auto" lineHeight={1.5}>
          <Text>{listFormat.format(row.original.jobTitles)}</Text>
        </Box>
      );
    },
  },
  {
    id: "jobTitleKeywords",
    header: "Job title keyword(s)",
    accessorKey: "jobTitleKeywords",
    enableSorting: false,
    cell: ({ row }) => {
      return (
        <Text maxHeight="100px" overflow="auto" lineHeight={1.5}>
          {listFormat.format(row.original.jobTitleKeywords)}
        </Text>
      );
    },
  },
  {
    id: "jobTitleExclusionKeywords",
    header: "Job title exclusion keyword(s)",
    accessorKey: "jobTitleExclusionKeywords",
    enableSorting: false,
    cell: ({ row }) => {
      return (
        <Text maxHeight="100px" overflow="auto" lineHeight={1.5}>
          {listFormat.format(row.original.jobTitleExclusionKeywords)}
        </Text>
      );
    },
  },
];

const useGetIsUpdatedByCurrentUser = () => {
  const user = useUser();

  return useCallback(
    (updatedBy: string | number) => {
      return updatedBy === user?.userId;
    },
    [user?.userId]
  );
};
