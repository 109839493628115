import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useScreen } from "utils";
import { useUserAgencyCompany } from "../../../../queries/companies/useUserAgencyCompany";
import { useParams } from "react-router";
import {
  deleteCompanyPixelDeliveryRecipient,
  editCompanyPixelDeliveryRecipient,
  getCompanyPixelDeliveryRecipient,
} from "../EditPixel/EditPixel.requests";
import { DeliveryRecipientScreenDefinition } from "./DeliveryRecipient.definition";
import { Button, Loader, ViewContainer } from "components";
import { ButtonGroup, chakra, Flex, Text } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { WebAnalyticsAccountView } from "@intentsify/types";
import { DeliveryRecipientForm } from "../components";
import React, { useEffect, useState } from "react";
import { MutateCompanyPixelDeliveryRecipientDTO } from "@intentsify/dto";
import { FiTrash2 } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { EditPixelScreenDefinition } from "../EditPixel";
import { useComponentColors } from "theme";
import { useCampaignMeta } from "../../../../shared/components";
import { DateTime } from "luxon";
import { ampli } from "../../../../tracking/amplitude";

export const deliveryRecipientSchema = z.object({
  email: z.string().email(),
  accountView: z.nativeEnum(WebAnalyticsAccountView),
  campaignId: z.number().nullable(),
  startDate: z.string(),
  endDate: z.string(),
});

export type DeliveryRecipientFormState = z.infer<
  typeof deliveryRecipientSchema
>;

const DeliveryRecipient = () => {
  useScreen(DeliveryRecipientScreenDefinition);
  const userAgencyCompany = useUserAgencyCompany();
  const selectedCompanyId = userAgencyCompany.data?.companyId;
  const { pixelId, recipientId: deliveryRecipientId } = useParams<
    "pixelId" | "recipientId"
  >();
  const queryClient = useQueryClient();
  const [isDeleting, setIsDeleting] = useState(false);
  const navigate = useNavigate();
  const componentColors = useComponentColors();
  const [isFormStateReady, setIsFormStateReady] = useState<boolean>(false);

  const { data, isFetching: isFetchingRecipient } = useQuery({
    queryKey: [
      "getCompanyPixelDeliveryRecipient",
      Number(selectedCompanyId),
      Number(pixelId),
      Number(deliveryRecipientId),
    ],
    queryFn: async () => {
      return getCompanyPixelDeliveryRecipient(
        Number(selectedCompanyId),
        Number(pixelId),
        Number(deliveryRecipientId)
      );
    },
    enabled: !!selectedCompanyId,
  });

  const { mutate: editRecipient, isLoading: isMutatingRecipient } = useMutation(
    {
      mutationFn: async (variables: {
        companyId: number;
        pixelId: number;
        deliveryRecipientId: number;
        params: MutateCompanyPixelDeliveryRecipientDTO;
      }) => {
        const { companyId, pixelId, deliveryRecipientId, params } = variables;
        return editCompanyPixelDeliveryRecipient(
          companyId,
          pixelId,
          deliveryRecipientId,
          {
            email: params.email,
            startDate: params.startDate,
            endDate: params.endDate,
            filters: {
              accountView: params.filters.accountView,
              campaignId: params.filters.campaignId,
            },
          }
        );
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [
            "getCompanyPixelDeliveryRecipient",
            Number(pixelId),
            Number(deliveryRecipientId),
          ],
        });
        queryClient.invalidateQueries({
          queryKey: ["getCompanyPixelDeliveryRecipients", Number(pixelId)],
        });
        ampli.websiteVisitorAnalyticsReportDeliveryRecipientEdited({
          recipientId: Number(deliveryRecipientId),
        });
      },
    }
  );

  const { mutate: deleteRecipient } = useMutation({
    mutationFn: async (variables: {
      companyId: number;
      pixelId: number;
      deliveryRecipientId: number;
    }) => {
      const { companyId, pixelId, deliveryRecipientId } = variables;
      return deleteCompanyPixelDeliveryRecipient(
        companyId,
        pixelId,
        deliveryRecipientId
      );
    },
    onSuccess: () => {
      setIsDeleting(false);
      queryClient.invalidateQueries({
        queryKey: ["getCompanyPixelDeliveryRecipients", Number(pixelId)],
      });
      ampli.websiteVisitorAnalyticsReportDeliveryRecipientDeleted({
        recipientId: Number(deliveryRecipientId),
      });
      navigate(
        EditPixelScreenDefinition.navigate({
          pixelId: Number(pixelId),
        })
      );
    },
  });

  const {
    register,
    formState: { errors },
    watch,
    control,
    handleSubmit,
    reset,
    setValue,
  } = useForm<DeliveryRecipientFormState>({
    mode: "onChange",
    resolver: zodResolver(deliveryRecipientSchema),
    defaultValues: {
      accountView: WebAnalyticsAccountView.AllAccounts,
      startDate: DateTime.now().toISO(),
      endDate: DateTime.now().plus({ month: 1 }).toISO(),
    },
  });

  useEffect(() => {
    if (data?.companyPixelDeliveryRecipient) {
      reset({
        email: data.companyPixelDeliveryRecipient.email,
        startDate: data.companyPixelDeliveryRecipient.startDate,
        endDate: data.companyPixelDeliveryRecipient.endDate,
        accountView: data.companyPixelDeliveryRecipient.filters.accountView,
        campaignId: data.companyPixelDeliveryRecipient.filters.campaignId,
      });
      setIsFormStateReady(true);
    }
  }, [data, reset]);

  const { campaignMeta, campaignMetaIsLoading } = useCampaignMeta({
    campaignId:
      data?.companyPixelDeliveryRecipient?.filters.campaignId || undefined,
  });

  const { email, startDate, endDate, accountView, campaignId } = watch();

  return (
    <ViewContainer>
      <Flex>
        {!isFetchingRecipient &&
        !isMutatingRecipient &&
        !campaignMetaIsLoading &&
        isFormStateReady &&
        data?.companyPixelDeliveryRecipient ? (
          <chakra.form
            width="50%"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onSubmit={handleSubmit((data) => {
              if (!isDeleting) {
                editRecipient({
                  companyId: Number(selectedCompanyId),
                  pixelId: Number(pixelId),
                  deliveryRecipientId: Number(deliveryRecipientId),
                  params: {
                    email: data.email,
                    startDate: data.startDate.slice(0, 10),
                    endDate: data.endDate.slice(0, 10),
                    filters: {
                      accountView: data.accountView,
                      campaignId: data.campaignId,
                    },
                  },
                });
              }
            })}
          >
            <Text
              color={componentColors.form.formLabelColor}
              fontSize="lg"
              fontWeight="bold"
            >
              Delivery recipient
            </Text>
            <DeliveryRecipientForm
              companyId={Number(selectedCompanyId)}
              pixelId={Number(pixelId)}
              errors={errors}
              register={register}
              control={control}
              recipient={{
                email,
                startDate,
                endDate,
                accountView,
                campaignId,
                campaignName: campaignMeta?.campaignName,
              }}
              setValue={setValue}
            />
            <Flex mt={4} justifyContent="flex-end">
              <ButtonGroup>
                <Button
                  leftIcon={<FiTrash2 />}
                  size="md"
                  variant="secondary"
                  onClick={() => {
                    deleteRecipient({
                      companyId: Number(selectedCompanyId),
                      pixelId: Number(pixelId),
                      deliveryRecipientId: Number(deliveryRecipientId),
                    });
                  }}
                >
                  Delete
                </Button>
                <Button size="md" variant="primary-teal" type="submit">
                  Save
                </Button>
              </ButtonGroup>
            </Flex>
          </chakra.form>
        ) : (
          <Loader label="Loading..." />
        )}
      </Flex>
    </ViewContainer>
  );
};

export { DeliveryRecipient };
