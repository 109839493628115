import { useToast } from "@chakra-ui/react";
import {
  BuyingGroupContactsAccounts,
  Campaign,
  CampaignAccounts,
  CampaignAdvancedSettings,
  CampaignBuyingGroup,
  CampaignDefinition,
  CampaignSettings,
  CompanyType,
  DomainEnhancementStrategy,
  Dynamic1Status,
} from "@intentsify/types";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { useDeepEffect } from "utils";
import { getCampaignById } from "../YourCampaigns/YourCampaigns.requests";
import {
  createCampaign,
  mutateCampaignAdvancedSettings,
  mutateCampaignBuyingGroup,
  mutateCampaignDomains,
  mutateCampaignKeywordsAndTopics,
  mutateCampaignSettings,
} from "./CampaignsWizard.requests";
import { YourCampaignsScreenDefinition } from "./YourCampaigns.definition";
import { isSavingWizardAtom } from "./components";
import { DEFAULT_MAXIMUM_BUYER_GROUP_PER_ACCOUNT_SIZE } from "./components/BuyingGroupStep/BuyingGroupForm/BuyingGroupForm.const";
import {
  autodiscoverySettingsStateSelector,
  dynamic1StateSelector,
  keywordsAndTopicsStateSelector,
  keywordsSelector,
  topicsSelector,
} from "./components/KeywordsAndTopicsStep";

type UseCampaignParams = {
  campaignId: number | undefined;
};

export const useCampaign = ({ campaignId }: UseCampaignParams) => {
  const setIsSaving = useSetRecoilState(isSavingWizardAtom);
  const queryClient = useQueryClient();

  const {
    data: campaign,
    isLoading: isLoadingCampaign,
    isFetching: isFetchingCampign,
  } = useQuery(
    ["campaign", campaignId],
    () => (campaignId ? getCampaignById(campaignId) : undefined),
    {
      enabled: Boolean(campaignId),
    }
  );

  const [keywordsAndTopicsState, setKeywordsAndTopicsState] = useRecoilState(
    keywordsAndTopicsStateSelector
  );

  const setKeywordsState = useSetRecoilState(keywordsSelector);
  const setTopicsState = useSetRecoilState(topicsSelector);
  const setAutodiscoverySettingsState = useSetRecoilState(
    autodiscoverySettingsStateSelector
  );
  const setDynamic1State = useSetRecoilState(dynamic1StateSelector);

  useDeepEffect(() => {
    if (campaign) {
      const {
        keywords,
        topics,
        autodiscoveryDescription,
        autodiscoveryUrls,
        autodiscoveryKeywords,
        autodiscoveryTopics,
        autodiscoveryProducts,
        autodiscoveryStatus,
        autodiscoveryFiles,
        dynamicOneStatus,
      } = campaign;

      setKeywordsState(
        keywords.map((k) => ({
          label: k.keyword,
          value: k.keywordId,
          metaData: {
            source: k.source,
          },
        }))
      );

      setTopicsState(topics);

      setAutodiscoverySettingsState({
        autodiscoveryDescription,
        autodiscoveryStatus,
        autodiscoveryUrls,
        autodiscoveryKeywords,
        autodiscoveryTopics,
        autodiscoveryProducts,
        autodiscoveryFiles,
      });

      setDynamic1State({
        processingStatus: campaign.dynamicOneStatus,
      });

      setKeywordsAndTopicsState({
        keywords,
        topics,
        autodiscoveryDescription,
        autodiscoveryStatus,
        autodiscoveryUrls,
        autodiscoveryKeywords,
        autodiscoveryTopics,
        autodiscoveryProducts,
        autodiscoveryFiles,
        dynamicOneStatus,
      });
    }
  }, [campaign, setKeywordsAndTopicsState]);

  const { mutate: createCampaignMutation } = useMutation(createCampaign, {
    onMutate: () => {
      setIsSaving(true);
    },
    onSettled: () => {
      setIsSaving(false);
    },
    onSuccess: ({ campaignId }, context) => {
      if (context.onSuccessCallback) {
        context.onSuccessCallback(campaignId);
      }
    },
  });

  const { mutate: updateCampaignSettings } = useMutation(
    mutateCampaignSettings,
    {
      onMutate: () => {
        setIsSaving(true);
      },
      onSettled: () => {
        setIsSaving(false);
      },
      onSuccess: (_, context) => {
        const previousCampaignState = queryClient.getQueryData<Campaign>([
          "campaign",
          campaignId,
        ]);

        if (previousCampaignState) {
          queryClient.setQueryData(["campaign", campaignId], {
            ...previousCampaignState,
            ...context.settings,
          });
        }

        if (context.onSuccessCallback) {
          context.onSuccessCallback();
        }

        queryClient.invalidateQueries({
          queryKey: ["campaignsList"],
          exact: false,
        });
      },
    }
  );

  const { mutate: updateDomainsSettings } = useMutation(
    campaignId
      ? mutateCampaignDomains(campaignId)
      : () =>
          Promise.reject(Error("mutateCampaignDomains: campaignId is missing")),
    {
      onMutate: () => {
        setIsSaving(true);
      },
      onSettled: () => {
        setIsSaving(false);
      },
      onSuccess: (campaign, context) => {
        const previousCampaignState =
          queryClient.getQueryData<CampaignDefinition>([
            "campaign",
            campaignId,
          ]);

        if (previousCampaignState) {
          queryClient.setQueryData(["campaign", campaignId], campaign);
        }

        if (context.onSuccessCallback) {
          context.onSuccessCallback();
        }

        queryClient.invalidateQueries({
          queryKey: ["campaignsList"],
          exact: false,
        });
      },
    }
  );

  const { mutate: updateCampaignBuyingGroup } = useMutation(
    mutateCampaignBuyingGroup,
    {
      onMutate: () => {
        setIsSaving(true);
      },
      onSettled: () => {
        setIsSaving(false);
      },
      onSuccess: (_, context) => {
        const previousCampaignState = queryClient.getQueryData<Campaign>([
          "campaign",
          campaignId,
        ]);

        if (previousCampaignState) {
          queryClient.setQueryData(["campaign", campaignId], {
            ...previousCampaignState,
            ...context.newSettings,
          });
        }

        if (context.onSuccessCallback) {
          context.onSuccessCallback();
        }

        queryClient.invalidateQueries({
          queryKey: ["campaignsList"],
          exact: false,
        });
      },
    }
  );

  const { mutate: updateCampaignKeywordsAndTopics } = useMutation(
    mutateCampaignKeywordsAndTopics,
    {
      onMutate: () => {
        setIsSaving(true);
      },
      onSettled: () => {
        setIsSaving(false);
      },
      onSuccess: (_, context) => {
        const previousCampaignState = queryClient.getQueryData<Campaign>([
          "campaign",
          campaignId,
        ]);

        if (previousCampaignState) {
          queryClient.setQueryData(["campaign", campaignId], {
            ...previousCampaignState,
            ...context.newSettings,
          });
        }

        if (context.onSuccessCallback) {
          context.onSuccessCallback();
        }

        queryClient.invalidateQueries({
          queryKey: ["campaignsList"],
          exact: false,
        });
      },
    }
  );

  const { mutate: updateCampaignAdvancedSettings } = useMutation(
    mutateCampaignAdvancedSettings,
    {
      onMutate: () => {
        setIsSaving(true);
      },
      onSettled: () => {
        setIsSaving(false);
      },
      onSuccess: (_, context) => {
        const previousCampaignState = queryClient.getQueryData<Campaign>([
          "campaign",
          campaignId,
        ]);

        if (previousCampaignState) {
          queryClient.setQueryData(["campaign", campaignId], {
            ...previousCampaignState,
            ...context.newSettings,
          });
        }

        if (context.onSuccessCallback) {
          context.onSuccessCallback();
        }

        queryClient.invalidateQueries({
          queryKey: ["campaignsList"],
          exact: false,
        });
      },
    }
  );

  const campaignSettings: CampaignSettings = {
    campaignId: campaign?.campaignId || 0,
    companyId: campaign?.companyId || 0,
    companyType: campaign?.companyType || CompanyType.Unspecified,
    repId: campaign?.repId || 0,
    customerSuccessManagerId: campaign?.customerSuccessManagerId || 0,
    displayName: campaign?.displayName || "",
    corporateEntityId: campaign?.corporateEntityId || 0,
    notes: campaign?.notes || "",
    startDate: campaign?.startDate || DateTime.now().toISODate(),
    endDate: campaign?.endDate || null,
    countries: campaign?.countries || [],
    businessUnits: campaign?.businessUnits || [],
    dynamicOneStatus: campaign?.dynamicOneStatus || Dynamic1Status.Unspecified,
  };

  const campaignBuyingGroup: CampaignBuyingGroup = {
    selectedPersonas: campaign?.selectedPersonas ?? [],
    buyingGroupContactsAccounts:
      campaign?.buyingGroupContactsAccounts ?? BuyingGroupContactsAccounts.NONE,
    maxBuyerGroupSizePerAccount:
      campaign?.maxBuyerGroupSizePerAccount ??
      DEFAULT_MAXIMUM_BUYER_GROUP_PER_ACCOUNT_SIZE,
    buyingGroupEqualDistribution:
      campaign?.buyingGroupEqualDistribution ?? false,
  };

  const campaignsDomains: CampaignAccounts = campaign?.accounts || {
    firmographicsFiles: [],
    files: [],
    blocklistedFiles: [],
    domainEnhancementStrategy: DomainEnhancementStrategy.unspecified,
  };

  const campaignAdvancedSettings: CampaignAdvancedSettings = {
    convertrCampaigns: campaign?.convertrCampaigns || [],
    beeswaxCampaignIds: campaign?.beeswaxCampaignIds || [],
    pixelIds: campaign?.pixelIds || [],
    deliveryRecipients: campaign?.deliveryRecipients || [],
    automatedIntentDeliveryEnabled:
      campaign?.automatedIntentDeliveryEnabled || false,
    automatedBuyingGroupDeliveryEnabled:
      campaign?.automatedBuyingGroupDeliveryEnabled || false,
    leadDomainCap: Number(campaign?.leadDomainCap),
    showBuyingGroupContactDetails:
      campaign?.showBuyingGroupContactDetails ?? false,
    averageDealSize: campaign?.averageDealSize ?? 0,
    averageSalesCycleMonths: campaign?.averageSalesCycleMonths ?? 0,
    leadActivationSummariesDeliveryEnabled:
      campaign?.leadActivationSummariesDeliveryEnabled || false,
  };

  return {
    isLoadingCampaign: isLoadingCampaign || isFetchingCampign,
    campaign,
    campaignSettings,
    campaignsDomains,
    campaignBuyingGroup,
    campaignKeywordsAndTopics: keywordsAndTopicsState,
    campaignAdvancedSettings,
    createCampaign: createCampaignMutation,
    updateCampaignSettings,
    updateDomainsSettings,
    updateCampaignKeywordsAndTopics,
    updateCampaignAdvancedSettings,
    updateCampaignBuyingGroup,
  };
};

export const useNothingToSaveToast = () => {
  const toast = useToast();

  return () =>
    toast({
      title: "Nothing to save. No changes have been made.",
      status: "info",
    });
};

export const useNavigateToCampaigns = () => {
  const navigate = useNavigate();

  return () => navigate(YourCampaignsScreenDefinition.navigate());
};
