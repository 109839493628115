import { useScreen } from "utils";
import { EditPixelScreenDefinition } from "./EditPixel.definition";
import { useParams } from "react-router";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Actions,
  Button,
  Loader,
  M_COLUMN_WIDTH,
  NoData,
  S_COLUMN_WIDTH,
  Table,
  ViewContainer,
  XS_COLUMN_WIDTH,
} from "components";
import {
  defaultPaginatedDeliveryRecipientsParams,
  deleteCompanyPixelDeliveryRecipient,
  getCompanyPixel,
  listCompanyPixelDeliveryRecipients,
} from "./EditPixel.requests";
import { useUserAgencyCompany } from "../../../../queries/companies/useUserAgencyCompany";
import { Box, Code, Flex, Text, useClipboard } from "@chakra-ui/react";
import { PixelForm, useEditCompanyPixel } from "../components";
import { useCallback, useEffect, useState } from "react";
import { Row } from "@tanstack/react-table";
import { CompanyPixelDeliveryRecipient } from "@intentsify/types";
import { useComponentColors } from "theme";
import { useNavigate } from "react-router-dom";
import { FiEye, FiTrash2 } from "react-icons/fi";
import { DeliveryRecipientScreenDefinition } from "../DeliveryRecipient";
import { PaginationType } from "../../../../components/Table/Table/Table.types";
import { FetchDataParamsWithToken } from "types";
import { ampli } from "../../../../tracking/amplitude";

const EditPixel = () => {
  useScreen(EditPixelScreenDefinition);
  const userAgencyCompany = useUserAgencyCompany();
  const selectedCompanyId = userAgencyCompany.data?.companyId;
  const { pixelId } = useParams<"pixelId">();
  const navigate = useNavigate();
  const componentColors = useComponentColors();
  const queryClient = useQueryClient();

  const { data, isFetching } = useQuery({
    queryKey: ["getCompanyPixel", Number(pixelId)],
    queryFn: async () => {
      return getCompanyPixel(Number(selectedCompanyId), Number(pixelId));
    },
    enabled: !!selectedCompanyId,
  });

  const [fetchDataParams, setFetchDataParams] = useState<
    FetchDataParamsWithToken<keyof CompanyPixelDeliveryRecipient>
  >(defaultPaginatedDeliveryRecipientsParams);

  const handleOnFetchDataChange = useCallback(
    (params: FetchDataParamsWithToken<keyof CompanyPixelDeliveryRecipient>) => {
      setFetchDataParams(params);
    },
    []
  );

  const { data: recipients, isFetching: isFetchingRecipients } = useQuery({
    queryKey: [
      "getCompanyPixelDeliveryRecipients",
      Number(selectedCompanyId),
      Number(pixelId),
      fetchDataParams,
    ],
    queryFn: async () => {
      return listCompanyPixelDeliveryRecipients(
        Number(selectedCompanyId),
        Number(pixelId),
        fetchDataParams
      );
    },
    enabled: !!selectedCompanyId,
  });

  const { mutate: deleteRecipient } = useMutation({
    mutationFn: async (variables: {
      companyId: number;
      pixelId: number;
      deliveryRecipientId: number;
    }) => {
      const { companyId, pixelId, deliveryRecipientId } = variables;
      return deleteCompanyPixelDeliveryRecipient(
        companyId,
        pixelId,
        deliveryRecipientId
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          "getCompanyPixelDeliveryRecipients",
          Number(selectedCompanyId),
          Number(pixelId),
        ],
      });
    },
  });

  const { onCopy, setValue, hasCopied } = useClipboard("");
  const script = `<script>
  function appendScriptTag(link) {
    var sc = document.createElement("script");
    sc.setAttribute("src", link);
    sc.setAttribute("defer", "");
    sc.setAttribute("type", "text/javascript");
    var elementToAppendTo = document.getElementsByTagName('html')[0] || document.
    getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0];
    elementToAppendTo.appendChild(sc);
  }
  
  var currentWebsiteUrl = encodeURIComponent(window.location.href);
  var serverUrl = "https://tracking.intentsify.io";
  var link = serverUrl + '/page-tracking/${
    data?.companyPixel?.displayName || ""
  }/' + currentWebsiteUrl;
  
  setTimeout(function(){
    appendScriptTag(link);
  }, 1000);
</script>`;

  useEffect(() => {
    setValue(script);
  }, [data, script, setValue]);

  const { editCompanyPixel, isLoading } = useEditCompanyPixel(
    Number(selectedCompanyId),
    Number(pixelId)
  );

  if (isFetching) {
    return <Loader />;
  }

  if (!data?.companyPixel) {
    return <NoData />;
  }

  const columns = [
    {
      header: "ID",
      accessorKey: "deliveryRecipientId",
      size: XS_COLUMN_WIDTH,
    },
    {
      header: "Email",
      accessorKey: "email",
      size: M_COLUMN_WIDTH,
    },
    {
      header: "Created",
      accessorKey: "createdAt",
      enableSorting: false,
      size: S_COLUMN_WIDTH,
      cell: ({ row }: { row: Row<CompanyPixelDeliveryRecipient> }) => {
        return (
          <Text as="span">
            {row.original.createdAt ? row.original.createdAt : "N/A"}
          </Text>
        );
      },
    },
    {
      header: "Actions",
      cell: ({ row }: { row: Row<CompanyPixelDeliveryRecipient> }) => {
        const items = [
          {
            label: "Show",
            icon: <FiEye />,
            onClick: () =>
              navigate(
                DeliveryRecipientScreenDefinition.navigate({
                  pixelId: Number(pixelId),
                  recipientId: Number(row.original.deliveryRecipientId),
                })
              ),
          },
          {
            label: "Delete",
            icon: <FiTrash2 />,
            onClick: () => {
              deleteRecipient({
                companyId: Number(selectedCompanyId),
                pixelId: Number(pixelId),
                deliveryRecipientId: Number(row.original.deliveryRecipientId),
              });
              ampli.websiteVisitorAnalyticsReportDeliveryRecipientDeleted({
                pixelId: Number(pixelId),
                recipientId: Number(row.original.deliveryRecipientId),
              });
            },
          },
        ];
        return <Actions items={items} />;
      },
    },
  ];

  return (
    <ViewContainer>
      <Flex width="100%" justifyContent="space-between" mb={4}>
        <Flex>
          {selectedCompanyId && (
            <PixelForm
              companyId={Number(selectedCompanyId)}
              pixelId={data.companyPixel.pixelId}
              pixelName={data.companyPixel.displayName}
              domains={data.companyPixel.domains}
              mode="edit"
              isLoading={isLoading}
              onSubmit={editCompanyPixel}
            />
          )}
        </Flex>
        <Flex flexGrow={1}>
          {data.companyPixel && (
            <Box margin="0 auto">
              <Code display="block" whiteSpace="preserve" padding={4}>
                {script}
              </Code>
              <Flex justifyContent="center" marginTop={4}>
                <Button
                  onClick={() => {
                    onCopy();
                    ampli.companyPixelScriptCopiedToClipboard({
                      pixelId: Number(pixelId),
                    });
                  }}
                >
                  {hasCopied ? "Copied" : "Copy to clipboard"}
                </Button>
              </Flex>
            </Box>
          )}
        </Flex>
      </Flex>
      <Flex width="100%" flexWrap="wrap">
        <Box width="100%" marginY={4}>
          <Text
            color={componentColors.form.formLabelColor}
            fontSize="lg"
            fontWeight="bold"
          >
            Delivery recipients
          </Text>
        </Box>
        <Table
          isFetching={isFetchingRecipients}
          maxWidth="100%"
          stretch
          data={recipients?.deliveryRecipients || []}
          columns={columns}
          nextPageToken={recipients?.nextPageToken}
          paginationType={PaginationType.TOKEN}
          defaultSort={[{ id: "deliveryRecipientId", desc: true }]}
          onFetchDataChange={handleOnFetchDataChange}
        />
      </Flex>
    </ViewContainer>
  );
};
export { EditPixel };
