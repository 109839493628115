import { Flex, Image, Text } from "@chakra-ui/react";
import {
  FetchDataParams,
  Nullable,
  SortDirection,
  Account,
  WebAnalyticsAccountView,
} from "@intentsify/types";
import { Badge, InfiniteScrollTable, Tooltip } from "components";
import { PaginationType } from "../../../../../../components/Table/Table/Table.types";
import { combineParamsAndFilters } from "utils";
import { listVisitingAccounts } from "./ListView.requests";
import { ColumnDef, Row } from "@tanstack/react-table";
import { toNumberDisplayValue } from "@intentsify/utils";
import { InfoIcon } from "@chakra-ui/icons";
import { AccountsTopUrlsExpandableRow } from "./components/AccountsTopUrlsExpandableRow";
import { Props } from "../../WebAnalytics.types";
import { ampli } from "../../../../../../tracking/amplitude";

const defaultParams = {
  order_by: "websiteVisits",
  order: SortDirection.DESC,
  page: 1,
  per_page: 50,
} satisfies FetchDataParams<keyof Account>;

type Filters = {
  pixelId: number;
  accountView: WebAnalyticsAccountView;
  campaignId: Nullable<number>;
  startDate: string;
  endDate: string;
  accountsIds: number[];
  visitedPages: string[];
};

const columns = [
  {
    header: "",
    accessorKey: "logoUrl",
    enableSorting: false,
    cell: ({ row }) => (
      <Flex display="flex">
        {row.original?.logoUrl && (
          <Image
            maxHeight={"50px"}
            maxWidth={"50px"}
            src={row.original?.logoUrl}
            mr={2}
          />
        )}
      </Flex>
    ),
  },
  {
    header: "Account",
    accessorKey: "domain",
  },
  {
    header: "Website Visits",
    accessorKey: "websiteVisits",
    enableSorting: true,
    cell: ({ row }: { row: Row<Account> }) => (
      <Text>{toNumberDisplayValue(row.original.websiteVisits)}</Text>
    ),
  },
  {
    header: "Page Views",
    accessorKey: "pageViews",
    enableSorting: true,
    cell: ({ row }: { row: Row<Account> }) => (
      <Text>{toNumberDisplayValue(row.original.pageViews)}</Text>
    ),
  },
  {
    header: "Unique Visitors",
    accessorKey: "uniqueVisitors",
    enableSorting: true,
    cell: ({ row }: { row: Row<Account> }) => (
      <Text>{toNumberDisplayValue(row.original.uniqueVisitors)}</Text>
    ),
  },
  {
    header: "Monitored In Intent Model",
    accessorKey: "isMonitoredInIntentModel",
    enableSorting: false,
    cell: ({ row }) => {
      return (
        <Badge fontWeight="bold">
          {row.original?.isMonitoredInIntentModel ? "Yes" : "No"}
        </Badge>
      );
    },
  },
  {
    header: () => {
      return (
        <Text>
          New Account to Site{" "}
          <Tooltip
            placement={"top-start"}
            aria-label="New Account to Site"
            label={
              "Accounts are “new to site” if they have visited after at least 90 days of inactivity."
            }
          >
            <InfoIcon w={3} h={3} verticalAlign="top" />
          </Tooltip>
        </Text>
      );
    },
    accessorKey: "newAccountsToSite",
    enableSorting: false,
    cell: ({ row }: { row: Row<Account> }) => (
      <Badge fontWeight="bold">
        {row.original?.newAccountsToSite ? "Yes" : "No"}
      </Badge>
    ),
  },
] satisfies ColumnDef<Account>[];

const ListView = ({
  pixelId,
  accountView,
  campaignId,
  startDate,
  endDate,
  accountsIds,
  visitedPages,
}: Props) => {
  const filters: Filters = {
    pixelId,
    accountView,
    campaignId,
    startDate,
    endDate,
    accountsIds,
    visitedPages,
  };

  const nonNullableFilters = Object.keys(filters).reduce((acc, key) => {
    const k = key as keyof Filters;
    if (!filters[k]) {
      return acc;
    } else {
      acc[k] = filters[k] as number | string;
      return acc;
    }
  }, {} as Record<string, number | string>);

  const fetchData = (params?: FetchDataParams<keyof Account>) => {
    return listVisitingAccounts(pixelId)(
      combineParamsAndFilters(
        {
          ...defaultParams,
          order: params?.order || defaultParams.order,
          order_by: params?.order_by || defaultParams.order_by,
          page: params?.page || defaultParams.page,
          per_page: params?.per_page || defaultParams.per_page,
        },
        nonNullableFilters
      )
    );
  };

  return (
    <Flex>
      <InfiniteScrollTable<Account, PaginationType.LIMIT_OFFSET>
        columns={columns}
        fetchData={fetchData}
        height={900}
        name={`WebAnalyticsListAccounts_${accountView}_${
          campaignId ?? "all_intent_models"
        }_${accountsIds.join(",") ?? "all_accounts"}_${startDate}_${endDate}_${
          visitedPages.join(",").length
        }`}
        estimatedRowHeight={60}
        width="100%"
        filters={{
          pixelId,
          accountView,
          campaignId,
          startDate,
          endDate,
          accountsIds: accountsIds.join(","),
          visitedPages: visitedPages.join(","),
        }}
        defaultSort={[{ id: "websiteVisits", desc: true }]}
        disableVirtualization={true}
        itemsPerPage={defaultParams.per_page}
        paginationType={PaginationType.LIMIT_OFFSET}
        renderExpandableRowComponent={(row) => {
          return (
            <AccountsTopUrlsExpandableRow
              domainId={row.original.domainId}
              campaignId={campaignId}
              startDate={startDate}
              endDate={endDate}
              pixelId={pixelId}
              accountView={accountView}
              visitedPages={visitedPages}
            />
          );
        }}
        onRowExpandToggle={(isExpanded, row) => {
          if (!isExpanded) {
            // will expand
            ampli.websiteVisitorAnalyticsVisitingCompaniesRowExpanded({
              pixelId,
              accountId: row.domainId,
            });
          }
        }}
      />
    </Flex>
  );
};

export { ListView };
